import React, { useRef, useState, useEffect } from 'react'

import { Alert, Button, Form } from 'react-bootstrap'
import { useParams } from 'react-router-dom'

import { useDataContext } from "../../../Context/DataContext"
import BackButton from '../../SMComponents/BackButton'

const AddProduct = () => {
  const { setSelectedFile, addItem, categories } = useDataContext()
    const file = useRef()
    const name = useRef()
    const price = useRef()
    const quantity = useRef()
    const description = useRef()
    const amountType = useRef()
    const category = useRef()

    const handleSend = () => {
      addItem({
        item: {
          name: name.current.value,
          price: price.current.value,
          quantity: quantity.current.value,
          description: description.current.value,
          amountType: amountType.current.value,
          category: category.current.value
        }
      })
      file.current.value = ''
      name.current.value = ''
      price.current.value = ''
      quantity.current.value = ''
      description.current.value = ''
      window.scrollTo(0, 0)
    }

    const uploadImage = (uploadedFile) => {
      if(uploadedFile.type.includes('image')) return setSelectedFile(uploadedFile)
      alert('Soubor není obrázek!')
      file.current.value = ''

    }
  return (
    <div style={{paddingTop: '100px', minHeight: "71vh"}}>
      
      <BackButton link="/admin" />
      <h1 className="text-center">Přidat předmět</h1>
      <Form className='w-100' onSubmit={(e) => e.preventDefault()}>
      <Form.Group className="m-3 w-100">
        <Form.Label>Jméno</Form.Label>
        <Form.Control type="text" placeholder="Jméno" ref={name} required />
      </Form.Group>
      <Form.Group className="m-3">
          <Form.Label>Kategorie</Form.Label>
          <select className="form-select w-100" ref={category}>
              {categories?.map((category) => {
                  return <option value={category.id_category} key={category.id_category}>{category.name}</option>
              })}
          </select>
      </Form.Group>
      <Form.Group className="m-3 w-100">
          <Form.Label>Obrazek</Form.Label>
          <Form.Control type="file" placeholder="E-mail" ref={file} name="sampleFile" onChange={(e) => { uploadImage(e.target.files[0]) }} required accept=".png, .jpg" />
        </Form.Group>
      <Form.Group className="m-3 w-100">
        <Form.Label>Popis</Form.Label>
        <textarea className='w-100' style={{minHeight: '150px'}} ref={description} />
      </Form.Group>
      <Form.Group className="m-3 w-100">
        <Form.Label>Počet</Form.Label>
        <Form.Control type="number" placeholder="Počet" ref={quantity} required />
      </Form.Group>
      <div className='d-flex'>
        <Form.Group className="m-3 w-100">
          <Form.Label>Cena</Form.Label>
          <Form.Control type="number" placeholder="Cena" ref={price} required />
        </Form.Group>
        <Form.Group className="m-3 w-100">
          <Form.Label>Veličina množství</Form.Label> {/* Vymyslel honza kubat */}
          <select className="form-select w-100" ref={amountType}>
            <option value="Ks">Kusy</option>
            <option value="Kg">Kila</option>
          </select>
        </Form.Group>
      </div>
        
        
        
        <Button onClick={handleSend} className="m-3 w-100" type="submit">Přidat</Button>
    </Form>
    </div>
  )
}

export default AddProduct