import { useRef, useState } from 'react'

import { Alert, Button, Form } from 'react-bootstrap'
import { useDataContext } from "../../../Context/DataContext"
import BackButton from '../../SMComponents/BackButton'

const ChangePass = () => {
  const [err, setErr] = useState('')
  const { changePasswd, logout } = useDataContext()
    
  const password = useRef()
  const passwordAgain = useRef()

  const handleSend = () => {
    if(password.current.value === passwordAgain.current.value){
      changePasswd(password.current.value)
      logout()
    }
    setErr('Hesla se neschodují.')
  }
  return (
    <div style={{paddingTop: '100px', minHeight: "71vh"}}>
      <BackButton link="/admin" />
      <h1 className='text-center'>Změnit heslo</h1>
        {err && <Alert variant='danger' className='m-3 w-100'>{err}</Alert>}
        <Form className='w-100' onSubmit={(e) => e.preventDefault()}>
          <Form.Group className="m-3 w-100">
            <Form.Label>Heslo</Form.Label>
            <Form.Control type="password" placeholder="Heslo" ref={password} required />
          </Form.Group>
          <Form.Group className="m-3 w-100">
            <Form.Label>Heslo znovu</Form.Label>
            <Form.Control type="password" placeholder="Heslo znovu" ref={passwordAgain} required />
          </Form.Group>
          <Button onClick={handleSend} className="m-3 w-100" type="submit">Změnit heslo</Button>
        </Form>
      </div>
  )
}

export default ChangePass