import React from 'react'

const TOS = () => {
  return (
    <div style={{paddingTop: '100px'}}>
      <h1><b>Obchodní podmínky</b></h1>
      <br/>
        <p style={{whiteSpace: "pre-line"}}>
          <b>1.Úvodní ustanovení</b>{`
             1.1 Tyto obchodní podmínky (dále jen "podmínky") se týkají nákupu zboží prostřednictvím e-shopu provozovaného společností Farma Rochov, se sídlem Rochov 12, 413 01, Rochov (dále jen "prodejce"). 
             1.2 Kontaktní údaje prodejce: Telefon: +420 732 191 116 E-mail: dominik.svoboda@crudex.cz 

          `}<b>2.Identifikační číslo </b>{`
          2.1 Identifikační číslo (IČO): 17686644. 

          `}<b>3.Registrace v obchodním rejstříku </b>{`
          3.1 Společnost není registrována v obchodním rejstříku

          `}<b>4.Ceny a platby</b>{`
          4.1 Všechny ceny zboží jsou uvedeny v CZK a jsou platné v době objednání.
          4.2 Zákazník má možnost platit za zboží hotově při převzetí. 
         
          `}<b>5.Dodací podmínky </b>{`
            5.1 Doba dodání si zákazník vybírá při objednávce
            5.2 Způsob doručení zboží bude domluven mezi prodejcem a zákazníkem. 
            5.3 Poštovné a balné jsou stanoveny dle domluvy s prodejcem a zákazníkem. 
          
          `}<b>6.Reklamační řád a záruční podmínky </b>{`
            6.1 Prodejce poskytuje záruku na jakost prodávaného zboží v souladu s platnými právními předpisy České republiky.
            6.2 Reklamace musí být uplatněny neprodleně po zjištění vady zboží. 
            6.3 Reklamační řád je k dispozici na webových stránkách e-shopu.

          `}<b>7.Povinnost převzít zásilku </b>{`
          7.1 Zákazník je povinen převzít zásilku v dobu, kterou si zvolil při objednávání. V případě, že zákazník zásilku nepřevezme, je prodejce oprávněn požadovat úhradu nákladů spojených s doručováním zásilky. 
          
          `}<b>8.Práva a povinnosti zákazníků </b>{`
          8.1 Zákazník má právo zrušit objednávku bez udání důvodu do 24 hodin od odeslání objednávky. Po uplynutí této lhůty může prodejce požadovat úhradu škody způsobené neoprávněným zrušením objednávky. 
8.2 Zákazník je povinen poskytnout prodejci správné a úplné údaje potřebné pro úspěšné zpracování a doručení objednávky. 
8.3 Zákazník je povinen zaplatit cenu za zboží a dodací náklady dohodnuté v objednávce. 

          `}<b>9.Zásady zpracování osobních údajů (GDPR) </b>{`
          9.1 Prodejce zpracovává osobní údaje zákazníků v souladu s platnými právními předpisy o ochraně osobních údajů, zejména s souladu s nařízením EU 2016/679 (GDPR). 
9.2 Podrobné informace o zpracování osobních údajů a právech zákazníků v souvislosti se zpracováním osobních údajů naleznete v dokumentu "Zásady ochrany osobních údajů", který je k dispozici na webových stránkách e-shopu.

          `}<b>10.Ostatní specifické podmínky týkající se sortimentu či služeb </b>{`
          10.1 Prodejce si vyhrazuje právo upravit nabídku zboží a služeb na e-shopu bez předchozího upozornění zákazníků. 
          10.2 Prodejce není zodpovědný za změny vlastností či dostupnosti zboží způsobené dodavatelem nebo výrobcem. 
          10.3 Zákazník souhlasí s tím, že zboží nabízené na e-shopu se může lišit vzhledem, chutí či konzistencí v závislosti na sezóně a pěstitelských podmínkách. 
          10.4 Prodejce se zavazuje dodržovat veškeré hygienické předpisy a normy při manipulaci s potravinami a zajišťuje, že zboží je skladováno a přepravováno v souladu s platnými předpisy. 10.5 Zákazník souhlasí s tím, že ovoce a zelenina, které jsou součástí zásilky, mohou vyžadovat domácí přípravu a skladování před konzumací. 
          
          `}<b>11.Závěrečná ustanovení</b>{`
          11.1 Tyto obchodní podmínky jsou platné ve znění uvedeném na webových stránkách e-shopu v den odeslání objednávky zákazníkem. 
11.2 Prodejce si vyhrazuje právo měnit tyto obchodní podmínky bez předchozího upozornění. Změny obchodních podmínek se však nevztahují na objednávky, které byly odeslány před účinností změn. 
11.3 Tyto obchodní podmínky jsou řízeny právním řádem České republiky. Veškeré spory, které vzniknou v souvislosti s těmito obchodními podmínkami nebo s nákupem zboží prostřednictvím e-shopu, budou řešeny příslušnými českými soudy.

          `}
        </p>
    </div>
  )
}

export default TOS