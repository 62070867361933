import { useState, useEffect } from 'react'
import { useDataContext } from "../../../Context/DataContext"
const ProductSellGraph = () => {
    const { apiLink, token, stats } = useDataContext()
  
    if(!stats) return <div></div>

    
    return (
        <>
        <table className="table-responsive">
      <thead>
        <tr>
          <th>Id produktu</th>
          <th>Jméno</th>
          <th>Počet prodaných kusů</th>
        </tr>
      </thead>
      <tbody>
      {(stats.sellStats)?.map((item, index) => {
          if(index > 7) return 
        return <tr className='orderRow' key={item.id_product}>
            <td className='orderRowDetail'>{item.id_product}</td>
            <td>{item.name}</td>
            <td>{item.quantity}</td>
        </tr>

      })}
      </tbody>
    </table></>
    );
}

export default ProductSellGraph