import React from 'react'
import { Button } from 'react-bootstrap'
import { useDataContext } from "../../Context/DataContext"

import { useNavigate } from 'react-router-dom'

const ControlPanel = () => {
  const { logout } = useDataContext()
  const history = useNavigate()
  return (
    <div >
      <div className='adminControlPanelTlacitka'>
        <Button className='buttonAdminControlPanel' onClick={() => history('/orders')}>Objednavky</Button>
        <Button className='buttonAdminControlPanel' onClick={() => history('/history')}>Hotove objednavky</Button>
        <Button className='buttonAdminControlPanel' onClick={() => history('/editNewsletter')}>Edit novinek</Button>
        <Button className='buttonAdminControlPanel' onClick={() => history('/adminGallery')}>Galerie</Button>
        <Button className='buttonAdminControlPanel' onClick={() => history('/editItems')}>Upravit produkty</Button>
        <Button className='buttonAdminControlPanel' onClick={() => history('/addProduct')}>Přidat produkt</Button>
        <Button className='buttonAdminControlPanel' onClick={() => history('/addNewsletter')}>Přidat novinku</Button>
        <Button className='buttonAdminControlPanel' onClick={() => history('/addCategory')}>Přidat kategorii</Button>
        <Button className='buttonAdminControlPanel' onClick={() => history('/manageCategories')}>Upravit kategorie</Button>
        <Button className='buttonAdminControlPanel' onClick={() => history('/addAdmin')}>Přidat admina</Button>
        <Button className='buttonAdminControlPanel' onClick={() => history('/createTag')}>Vytvořit Tag</Button>
        <Button className='buttonAdminControlPanel' onClick={() => history('/editTag')}>Upravit Tag</Button>
        <Button className='buttonAdminControlPanel' onClick={() => history('/deleteTag')}>Vymazat Tag</Button>
        <Button className='buttonAdminControlPanel' onClick={() => history('/promoCodes')}>Promo Kody</Button>
        <Button className='buttonAdminControlPanel' onClick={() => history('/setDisabledDays')}>Nastavit nepracovní dny</Button>
        <Button className='buttonAdminControlPanel' onClick={() => history('/changeDeliveryPrice')}>Změnit cenu dopravy</Button>
        <Button className='buttonAdminControlPanel' onClick={() => history('/changePassword')}>Změnit heslo</Button>
        <Button className='buttonAdminControlPanel' onClick={() => logout()}>Odhlásit se</Button>
        
      </div>
    </div>
  )
}

export default ControlPanel