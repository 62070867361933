import { useEffect, useState } from "react"
import { useDataContext } from "../../../Context/DataContext"
import BackButton from "../../SMComponents/BackButton"
import Item from "./Components/Item"
import TagCreator from "./CreateTag/TagCreator"
import TagsList from "./CreateTag/TagsList"

const CreateTag = () => {
    const { items, tags, setTags } = useDataContext()
    const [shownItem, setItem] = useState()
    const [showTags, setShowTags] = useState([])

    const getRandomItem = () => {
        setItem(items[Math.floor(Math.random()*items.length)])
    }

    const displayTag = async (tag) => {
        let _tags = tags

        _tags[0] = {
            id_tag: 0,
            name: tag.name,
            color: tag.color,
            title: tag.title
        }
        await setTags([])
        await setTags(_tags)
    }

    /* const changeShowTag = async (index) => {
        let x = showTags
        x[index] = !x[index]
        await setShowTags([])
        await setShowTags(x)
    } */

    const addTag = async(tag) => {
        let x = showTags
        if(showTags.includes(tag)){
            x.splice(x.indexOf(tag), 1)
        }
        else {
            x.push(tag)
        }
        await setShowTags([])
        await setShowTags(x)
    }
    
    useEffect(() => {
        if(!items) return
        getRandomItem()
    }, [items])

	return <div style={{ paddingTop: "100px", minHeight: "71vh" }}>
        <BackButton link="/admin" />
        <button onClick={() => getRandomItem()}>Refresh Item</button>
        <div className="TagCreator">
            {tags && shownItem && <Item tags={tags} item={shownItem} showTags={showTags} />}
            <TagCreator displayTag={displayTag} />
            <TagsList tags={tags} showTags={showTags} addTag={addTag} />
        </div>
    </div>
}

export default CreateTag
