const TagsList = ({ tags, setShownTag, shownTag }) => {
  return (
    <div className='tagList'>
    <hr />
        {tags.map((tag, index) => {
            return <div key={tag.id_tag}>
                <div className='tagTagList'>
                <h6>{tag.name}</h6>
                <input type="radio" checked={shownTag == tag} onChange={() => setShownTag(tag)} />
            </div>   
            <hr />
            </div>
        })}
    </div>
  )
}

export default TagsList