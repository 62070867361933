import React, { useEffect, useState } from 'react'
import { useDataContext } from '../../Context/DataContext'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from 'react-bootstrap';

const SetDisabledDays = () => {
    const [selectedDisabledDates, setSelectedDisabledDays] = useState([])
    const [newSelectedDisabledDates, setNewSelectedDisabledDays] = useState([])
    const { apiLink } = useDataContext()

    useEffect(() => {
        const getDisabledDates = async () => {
            let res = await fetch(apiLink + '/disabledDates')
            let data = await res.json()
            setSelectedDisabledDays(data.map(function(item) {
                return new Date(item)
            }))
        }

        getDisabledDates()
    }, [])

    const addDisabledDate = (date) => {
        setSelectedDisabledDays([...selectedDisabledDates, date])
        setNewSelectedDisabledDays([...newSelectedDisabledDates, date])
    }    

    const sendIt = () => {
        fetch(apiLink + '/addDisabledDates', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(newSelectedDisabledDates)
        })
    }

    return (
        <div style={{paddingTop: "100px", minHeight: "70vh"}}>
            <DatePicker onChange={addDisabledDate} format="dd/MM/y" minDate={(new Date()).setDate((new Date()).getDate() + 1)} className="w-100" excludeDates={selectedDisabledDates} />
            <Button onClick={sendIt}>Odeslat</Button>
        </div>
    )
}

export default SetDisabledDays