import React, { useEffect, useRef, useState } from 'react'
import { useDataContext } from '../../../Context/DataContext'
import BackButton from '../../SMComponents/BackButton'
import { Button } from 'react-bootstrap'

const PromoCodes = () => {
  const [shownData, setShownData] = useState()
  const { promo_codes, history, token, apiLink, getPromoCodes } = useDataContext()
  
  useEffect(() => {
      setShownData(promo_codes)
    }, [promo_codes]);

  const search = useRef()

  const handleSearch = () => {
    let val = search.current.value
    const filteredData = promo_codes.filter((el) => {
        if (val === '') {
            return el;
        }
        else {
            return (el.code).includes(val.toUpperCase())
        }
    })
    setShownData(filteredData.sort((a, b) => ((a.code)).localeCompare((b.code))))
  }

  const deletePromoCode = async (promo_code) => {
    await fetch(apiLink + '/admin/deletePromoCode', {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'x-auth-token': token},
      body: JSON.stringify(promo_code)
    })
    getPromoCodes()
  }

  return (
    <div style={{paddingTop: '100px', minHeight: "71vh"}}>
        <BackButton link="/admin" />
      <div className='w-100 d-flex justify-content-center mb-3'><Button className='w-75' onClick={() => {history('/createPromoCode')}}>Vytvořit nový</Button></div>
      <div className='d-flex'>
      </div>
      <div className='d-flex justify-content-center'>
        <input type="search" className="form-control w-75" placeholder="Hledej" onChange={handleSearch} ref={search}/>
      </div>
        <table className="table-responsive mt-3">
      <thead>
        <tr>
          <th>Kod</th>
          <th>Sleva %</th>
          <th>Použití</th>
          <th>Max použití</th>
          <th>Upravit</th>
        </tr>
      </thead>
      <tbody>
      {promo_codes && shownData?.map(item => {
        return <tr key={item.id_code} style={item.isAvalible == 0 ? { backgroundImage: "linear-gradient(rgb(0 0 0/20%) 0 0)" } : {}} >
          <td>{item.code}</td>
          <td>{item.discount}</td>
          <td>{item.used_count}</td>
          <td>{item.max_usage == 0 ? "Nekonečno" : item.max_usage}</td>
          <td className='d-flex justify-content-center flex-wrap' >{/* <Button className='m-2 w-25'>Upravit</Button> */}<Button className='m-2 w-100' onClick={() => deletePromoCode(item)}>Smazat</Button></td>
        </tr>
      })}
      </tbody>
    </table>
    </div>
  )
}

export default PromoCodes