import React from 'react'

const Reclamation = () => {
  return (
    <div style={{paddingTop: '100px'}}>
      <h1><b>Reklamační řád</b></h1>
      <br/>
        <p style={{whiteSpace: "pre-line"}}>
          <b>1.Úvodní ustanovení</b>{`
            1.1 Tento reklamační řád (dále jen "řád") upravuje postupy při uplatňování reklamací za zboží zakoupené prostřednictvím e-shopu provozovaného společností Farma Rochov, se sídlem Rochov 12, 413 01, Rochov (dále jen "prodejce"). 
            1.2 Reklamační řád je vyhotoven v souladu s platnými právními předpisy České republiky, zejména se zákonem č. 89/2012 Sb., občanský zákoník, a zákonem č. 634/1992 Sb., o ochraně spotřebitele. 

            `}<b>2.Vymezení pojmu "zákazník" </b>{`
            2.1 Zákazníkem je osoba, která s prodejcem uzavřela kupní smlouvu prostřednictvím e-shopu. Zákazníkem může být spotřebitel nebo podnikatel. 

          `}<b>3.Reklamace  </b>{`
          3.1 Zákazník má právo uplatnit reklamaci u prodejce v případě, že zboží zakoupené prostřednictvím e-shopu vykazuje vady. 
            3.2 Reklamace zákazníka se řídí reklamačním řádem a platnými právními předpisy České republiky. 


          `}<b>4.Reklamační lhůty </b>{`
          4.1 Spotřebitel má právo uplatnit reklamaci u prodejce u zboží, které vykazuje vady v době převzetí zákazníkem, a to ve lhůtě 24 měsíců od převzetí zboží. 
          4.2 Podnikatel má právo uplatnit reklamaci u prodejce u zboží, které vykazuje vady v době převzetí zákazníkem, a to ve lhůtě 12 měsíců od převzetí zboží.           

          `}
          <b>5.Postup při uplatnění reklamace</b>{`
          5.1 Zákazník je povinen uplatnit reklamaci bez zbytečného odkladu od zjištění vady. 
          5.2 Reklamace se uplatňuje elektronicky, a to prostřednictvím e-mailové adresy dominik.svoboda@crudex.cz.
          5.3 Při uplatňování reklamace je zákazník povinen uvést následující informace: 
          a) Jméno a příjmení, kontaktní adresu a e-mail nebo telefonní číslo. 
          b) Datum nákupu. 
          c) Popis zjištěné vady a fotografii vady, pokud je to možné a vhodné. 
          d) Požadavek zákazníka ohledně způsobu vyřízení reklamace (oprava, výměna, vrácení peněz apod.). 
          5.4 Zákazník je povinen zboží doručit na adresu prodejce pro posouzení reklamace, pokud prodejce nerozhodne jinak. Náklady na doručení zboží prodejci hradí zákazník, který má právo na jejich úhradu, pokud se prokáže oprávněnost reklamace. 
          5.5 Prodejce je povinen potvrdit zákazníkovi přijetí reklamace, a to buď prostřednictvím e-mailu nebo písemně.           

          `}

          <b>6.Vyřízení reklamace</b>{`
          6.1 Prodejce je povinen vyřídit reklamaci bez zbytečného odkladu, nejpozději do 30 dnů od jejího přijetí. Tuto lhůtu může prodejce prodloužit se souhlasem zákazníka. 
          6.2 Prodejce posoudí oprávněnost reklamace a informuje zákazníka o výsledku posouzení. 
          6.3 Pokud je reklamace oprávněná, prodejce zboží opraví, vymění nebo vrátí kupní cenu podle požadavku zákazníka. Náklady na opravu, výměnu nebo vrácení zboží hradí prodejce. 
          6.4 Pokud je reklamace neoprávněná, prodejce vrátí zboží zákazníkovi na jeho náklady. 
          
          `}

          <b>7.Závěrečná ustanovení </b>{`
          7.1 Prodejce si vyhrazuje právo měnit tento reklamační řád. Aktuální verze reklamačního řádu je vždy k dispozici na webových stránkách e-shopu a je zákazníkovi poskytnuta při uzavření kupní smlouvy. 
          7.2 Tento reklamační řád nabývá účinnosti dne 1. dubna 2023. 
          7.3 Pokud se jedná o spotřebitelský spor, který se nepodařilo vyřešit vzájemnou dohodou, má spotřebitel právo podat návrh na mimosoudní řešení takového sporu určenému subjektu mimosoudního řešení spotřebitelských sporů, kterým je: Česká obchodní inspekce Ústřední inspektorát - oddělení ADR Štěpánská 15 120 00 Praha 2 Email: adr@coi.cz Web: https://adr.coi.cz Spotřebitel může také využít platformy pro řešení spotřebitelských sporů online (ODR) dostupné na webové adrese: http://ec.europa.eu/consumers/odr. 
          7.4 V případě, že se jedná o spor mezi podnikatelem a prodejcem, který se nepodařilo vyřešit vzájemnou dohodou, mají strany právo řešit spor před příslušným soudem v České republice. 
          
          `}

          <b>8.Osobní údaje</b>{`
          8.1 Prodejce zpracovává osobní údaje zákazníků v souladu s platnými právními předpisy, zejména s souladu se zákonem č. 101/2000 Sb., o ochraně osobních údajů, a nařízením Evropského parlamentu a Rady (EU) 2016/679 (obecné nařízení o ochraně údajů - GDPR). 
          8.2 Prodejce zpracovává osobní údaje zákazníků za účelem plnění kupní smlouvy, řešení reklamací a pro marketingové účely, pokud zákazník udělil souhlas s takovým zpracováním. 
          8.3 Prodejce uchovává osobní údaje zákazníků po dobu nezbytně nutnou pro účely, pro které jsou údaje zpracovávány, a v souladu s platnými právními předpisy. 
          8.4 Zákazník má právo na přístup ke svým osobním údajům, opravu nebo výmaz těchto údajů, omezení zpracování, právo na přenositelnost údajů, právo vznést námitku proti zpracování a právo na informace o zpracování svých osobních údajů. 
          8.5 Zákazník má právo kdykoli odvolat svůj souhlas se zpracováním svých osobních údajů pro marketingové účely. Odvolání souhlasu nemá vliv na zpracování údajů prováděné před jeho odvoláním. 
          8.6 Pokud má zákazník podezření na neoprávněné zpracování svých osobních údajů, může podat stížnost u Úřadu pro ochranu osobních údajů, se sídlem Pplk. Sochora 27, 170 00 Praha 7, Česká republika, nebo u jiného příslušného dozorového orgánu. 
          8.7 Zákazník bere na vědomí, že prodejce je povinen poskytnout svým osobním údajům třetím stranám za účelem plnění kupní smlouvy (např. přepravci zboží) nebo na základě zákonné povinnosti. 
          8.8 Prodejce může pověřit zpracováním osobních údajů zákazníků tzv. zpracovatele, který zpracovává osobní údaje jménem prodejce a na základě jeho pokynů. V takovém případě uzavře prodejce se zpracovatelem smlouvu o zpracování osobních údajů, která stanoví povinnosti zpracovatele ohledně ochrany a bezpečnosti zpracovávaných osobních údajů. 
          
          `}

          <b>9.Odpovědnost za škodu </b>{`
          9.1 Prodejce nenese odpovědnost za škodu vzniklou zákazníkovi v důsledku neoprávněného zpracování jeho osobních údajů třetími osobami, pokud nedochází k porušení povinností prodejce v souvislosti s ochranou osobních údajů. 
          
          `}
        </p>
    </div>
  )
}

export default Reclamation