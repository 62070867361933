
import { useDataContext } from "../Context/DataContext"
import { GiHamburgerMenu } from 'react-icons/gi'
import { FiShoppingCart } from 'react-icons/fi'

import { Badge } from '@mui/material';
import { useNavigate } from 'react-router-dom'
import React, {useState, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import Logo from '../Images/Logo.png'

const Header = () => {
    const { shoppingCart, isAdmin, showShoppingCart, numberOfItemsInCart } = useDataContext()
    const [showNavbar, setShowNavbar] = useState(false);
    const history = useNavigate()
    const [div, setDiv] = useState(null)
    const handleClick = async () => {
        await window.scrollTo(0, 0)
        history('/sendOrder')
    }

    const handleLeave = () => {
        setDiv(null)
        if(shoppingCart.length > 0) document.getElementById('shoppingCartIcon').style = "opacity: 1;"
    }
    
    const handleEnter = (e) => {
        if(div !== null) return 
        setDiv(showShoppingCart(e, handleLeave))
    }
    
	const handleShowNavbar = () => {
        setShowNavbar(!showNavbar);
	}
    
    
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange)
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange)
        }
    }, []);
    
    const [width, setWidth] = useState(window.innerWidth)
    const isMobile = width <= 1000;
    
    return (
        <><div>
			<nav className='navbar'>
				<div className='container'>
					<div className='logo'>
                        <NavLink to='/' className='nav-item m-0'>
                            <img src={Logo} alt="" id="logos" /> {/* <b style={{color:'rgb(70, 174, 10)', fontSize: '18px'}}>Farma rochov</b> */}
                        </NavLink>
					</div>
                    
					<div className="d-flex flex-nowrap">
                    {isMobile && <div className="mx-5">{shoppingCart?.length > 0 && <span className="odkaz" onClick={handleClick} to="/sendOrder">
                        {numberOfItemsInCart > 0 && <Badge badgeContent={Math.ceil(numberOfItemsInCart)} color="success" sx={{ "& .MuiBadge-badge": { color: 'white', backgroundColor: 'hsl(98deg 89% 36%)', } }}> <FiShoppingCart color={'hsl(98deg 89% 36%)'} size={'25px'} onMouseEnter={handleEnter} id="shoppingCartIcon" style={{zIndex: 999}} /> </Badge>}
                    </span>}</div>}
                    <div className='menu-icon' onClick={handleShowNavbar}>
                        <GiHamburgerMenu size={30} />
					</div>
                    
                    </div>
                    
					<div className={`nav-elements  ${showNavbar && 'active'}`}>
						<ul>
							<li>
								<NavLink to='/' className='nav-item' end onClick={handleShowNavbar}>
									Domů
								</NavLink>
							</li>
							<li>
                            <NavLink to='/shopPage' className='nav-item' onClick={async () => {handleShowNavbar(); await window.scrollTo(0, 0)}}>
                            Produkty
								</NavLink>
							</li>
                            <li>
								<NavLink to='/newsletter' className='nav-item' end onClick={async () => {handleShowNavbar(); await window.scrollTo(0, 0)}}>
									Novinky
								</NavLink>
							</li>
							<li>
								<NavLink to='/aboutUs' className='nav-item' onClick={async () => {handleShowNavbar(); await window.scrollTo(0, 0)}}>
									O nás
								</NavLink>
							</li>

                            <li>
                                <NavLink to='/gallery' className='nav-item' onClick={async () => {handleShowNavbar(); await window.scrollTo(0, 0)}}>
                                    Galerie
                                </NavLink>
                            </li>

                            {isAdmin && <li>
								<NavLink to='/admin' className='nav-item' onClick={async () => {handleShowNavbar(); await window.scrollTo(0, 0)}}>
									Admin
								</NavLink>
							</li>}
                            
                            <li>
                                <span className="odkaz" onClick={handleClick} to="/sendOrder">
                                {numberOfItemsInCart > 0 && !isMobile && 
                                    <Badge badgeContent={Math.ceil(numberOfItemsInCart)} color="success" sx={{
                                        "& .MuiBadge-badge": {
                                          color: 'white',
                                          backgroundColor: 'hsl(98deg 89% 36%)',
                                        }
                                      }}>
                                        <FiShoppingCart color={'hsl(98deg 89% 36%)'} size={'25px'} onMouseEnter={handleEnter} id="shoppingCartIcon" style={{zIndex: 999}} />
                                    </Badge>}
                                </span>
							</li>
						</ul>
					</div>
				</div>
			</nav></div>
            {isMobile ? '' : div}
		</>
    )
}


export default Header