import React from 'react'

const GDPR = () => {
  return (
    <div style={{paddingTop: '100px'}}>
      <h1><b>Zásady ochrany osobních údajů</b></h1>
      <br/>
        <p style={{whiteSpace: "pre-line"}}>
          <b>1.Úvodní ustanovení</b>{`
            1.1 Tyto zásady ochrany osobních údajů (dále jen "zásady") se týkají zpracování osobních údajů zákazníků prostřednictvím e-shopu provozovaného společností Farma Rochov, se sídlem Rochov 12, 413 01, Rochov (dále jen "prodejce"). 
            1.2 Prodejce zpracovává osobní údaje zákazníků v souladu s platnými právními předpisy o ochraně osobních údajů, zejména s nařízením EU 2016/679 (GDPR).             

          `}<b>2.Kategorie zpracovávaných osobních údajů </b>{`
          2.1 Prodejce zpracovává následující kategorie osobních údajů zákazníků: 
          a) Identifikační údaje: jméno a příjmení, adresa, e-mail, telefonní číslo. 
          b) Transakční údaje: údaje o objednávkách, platebních a dodacích informacích.
          c) Technické údaje: informace o zařízení, které zákazník používá pro přístup k e-shopu, IP adresa, údaje o prohlížeči a interakci se stránkou. 

          `}<b>3.Účely zpracování osobních údajů </b>{`
          3.1 Prodejce zpracovává osobní údaje zákazníků pro následující účely: 
          a) Zpracování a plnění objednávek, včetně doručení zboží a komunikace s zákazníkem.
          b) Fakturace a vyřizování reklamací. 
          c) Zasílání marketingových sdělení, pokud zákazník souhlasil s přijímáním takových sdělení. 
          d) Zlepšení a personalizace e-shopu a služeb prodejce. e) Plnění právních povinností prodejce, např. účetní a daňové povinnosti.

          `}<b>4.Právní základ zpracování osobních údajů</b>{`
            4.1 Zpracování osobních údajů pro účely uvedené v bodech 3.1 a) až c) je založeno na plnění smlouvy mezi prodejcem a zákazníkem nebo na opatřeních přijatých před uzavřením smlouvy na žádost zákazníka.
            4.2 Zpracování osobních údajů pro účely uvedené v bodě 3.1 d) je založeno na oprávněném zájmu prodejce v poskytování kvalitních a personalizovaných služeb zákazníkům. 
            4.3 Zpracování osobních údajů pro účely uvedené v bodě 3.1 e) je založeno na plnění právních povinností, kterým prodejce podléhá. 

          `}
          <b>5.Doba uchovávání osobních údajů </b>{`
          5.1 Prodejce uchovává osobní údaje zákazníků po dobu nezbytně nutnou pro splnění účelů, pro které byly údaje získány, nebo po dobu stanovenou právními předpisy.

          `}

          <b>6.Předávání osobních údajů třetím stranám </b>{`
          6.1 Prodejce může předávat osobní údaje zákazníků třetím stranám, pokud je to nezbytné pro plnění účelů zpracování nebo pokud to vyžaduje právní předpis. Třetí strany zahrnují například dopravce, poskytovatele platebních služeb, účetní nebo daňoví poradci.

          `}

          <b>7.Práva zákazníků v souvislosti se zpracováním osobních údajů </b>{`
          7.1 Zákazníci mají právo: 
          a) Na přístup ke svým osobním údajům. 
          b) Na opravu nepřesných nebo neaktuálních údajů. 
          c) Na výmaz osobních údajů, pokud neexistuje důvod pro jejich další zpracování. 
          d) Na omezení zpracování osobních údajů v určitých případech. 
          e) Na přenositelnost osobních údajů do jiného správce. 
          f) Vznést námitku proti zpracování osobních údajů založeného na oprávněném zájmu prodejce. 
          g) Odvolat souhlas se zpracováním osobních údajů, pokud byl zpracování založeno na souhlasu zákazníka. Odvolání souhlasu nemá vliv na zákonnost zpracování údajů před jeho odvoláním. 
          h) Podat stížnost u dozorového úřadu, pokud má zákazník za to, že zpracování osobních údajů je v rozporu s právními předpisy.

          `}

          <b>8.Zabezpečení osobních údajů </b>{`
          8.1 Prodejce se zavazuje k zabezpečení osobních údajů zákazníků a přijímá vhodná technická a organizační opatření k ochraně osobních údajů před ztrátou, zničením, neoprávněným přístupem nebo změnou. 

          `}

          <b>9.Používání cookies </b>{`
          9.1 E-shop prodejce používá cookies za účelem zlepšení uživatelského komfortu a sledování návštěvnosti. Zákazník souhlasí s používáním cookies tím, že pokračuje v prohlížení e-shopu. Zákazník má možnost nastavit svůj prohlížeč tak, aby cookies odmítal nebo vymazal uložené cookies. 

          `}

          <b>10.Změny zásad </b>{`
          10.1 Prodejce si vyhrazuje právo měnit nebo doplňovat tyto zásady ochrany osobních údajů. O změnách bude prodejce informovat zákazníky prostřednictvím e-shopu nebo e-mailu. 

          `}

          <b>11.Kontaktní údaje </b>{`
          11.1 Pokud má zákazník dotazy nebo připomínky týkající se zpracování osobních údajů, může se obrátit na prodejce prostřednictvím e-mailu na adrese dominik.svoboda@crudex.cz nebo telefonicky na čísle +420 732 191 116. 

          `}
          <b>Tyto zásady ochrany osobních údajů nabývají účinnosti dne 24. března 2023.</b>
        </p>
    </div>
  )
}

export default GDPR