import { useRef } from 'react'
import { useDataContext } from "../../../../Context/DataContext"

const TagCreator = ({ displayTag }) => {
    const name = useRef(), color = useRef(), title = useRef()
    const { addTag } = useDataContext()

    const onChange = () => {
        displayTag({name: name.current.value, color: color.current.value, title: title.current.value})
    }

    const create = () => {
        if(name.current.value.length < 3 || color.current.value.length < 3 || title.current.value.length < 3) return
        addTag({name: name.current.value, color: color.current.value, title: title.current.value})
    }

  return (
    <div className='creatorBody'>
        <input type="text" placeholder='name' ref={name} onChange={onChange} defaultValue={'Novy tag'} />
        <input type="text" placeholder='title' ref={title} onChange={onChange} defaultValue={'Novy tag'} />
        <input type="color" placeholder='color' ref={color} onChange={onChange} defaultValue={'#ff0000'} />
        <button onClick={create}>Vytvořit</button>
    </div>
  )
}

export default TagCreator