import {Alert, Button, Form} from 'react-bootstrap'
import React, {useRef, useState} from 'react'
import InputMask from 'react-input-mask';
import {useDataContext} from "../Context/DataContext"

import {useNavigate} from 'react-router-dom'


const Contacts = () => {
    const history = useNavigate()
    const {sendForm, setInfo, info} = useDataContext()
    const email = useRef()
  const phoneNumber = useRef()
  const message = useRef()
  const [sent, setSent] = useState(false)
  const [err, setErr] = useState('')
  const onClickSend = () => {
    if(email.current.value === '') return setErr("Musíte vyplnit svůj E-Mail!")
    if(phoneNumber.current.value === '') return setErr("Musíte vyplnit svoje telefonní číslo!")
    if(message.current.value === '') return setErr("Musíte napsat nějakou zprávu!")
    if(validateEmail(email.current.value) == null) return setErr('Napište existující E-mail!')
    
    let json = {
      email: email.current.value,
      phoneNumber: phoneNumber.current.value,
      message: message.current.value
    }
    sendForm(json)
    setInfo('Zpráva odeslána!')
    setSent(true)
    history('/')
    email.current.value = ""
    phoneNumber.current.value = ""
    message.current.value = ""
    window.scrollTo(0, 0)
    setErr('')
  }

  const validateEmail = (email) => {
    return String(email).toLowerCase().match( /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ )
  }
  return (
<>


    <div className='' style={{borderTop:'10px solid hsl(98deg 89% 36%)', backgroundColor: "hsl(0deg 0% 92%)", boxShadow: "0px 2px 25px rgba(0, 0, 0, 0.1)"}}>
   
      <br></br>
        
        <div className='m-3 text-center'>
            <h1>Napište nám 😁</h1>
          
          <hr style={{width: "60px", color: "hsl(98deg 89% 36%)", backgroundColor: "hsl(98deg 89% 36%)", height: "3px", opacity: '1'}} className=" mt-0 d-inline-block mx-auto" />
          </div>

        <div className='w-100 d-flex justify-content-center row m-0'>
            <Form style={{width: "30%", minWidth: "300px"}} className={"mx-3"}>
                {info && <Alert variant='success' className='w-100'>{info}</Alert>}
                {err && <Alert variant='danger' className='w-100'>{err}</Alert>}
                <Form.Group className="w-100">
                    <Form.Label className={"m-0"}>E-mail</Form.Label>
                    <Form.Control type="text" ref={email} required/>
                </Form.Group>

                <Form.Group>
                    <Form.Label className={"m-0"}>Telefonní číslo</Form.Label>
                    <InputMask mask="+420 999 999 999" maskChar="" className='w-100 form-control' ref={phoneNumber}
                               required/>
                </Form.Group>

                <Form.Group className=" w-100">
                    <Form.Label className={"m-0"}>Zpráva</Form.Label>
                    <Form.Control as={"textarea"} className='w-100' style={{resize: "none"}} rows={5} ref={message}/>
                </Form.Group>
                <br/>
                <Button style={{backgroundColor: 'hsl(98deg 89% 36%)', marginBottom: '5%'}} className="w-100 border-0"
                        onClick={onClickSend} disabled={sent}>Odeslat</Button>

            </Form>
        </div>
        
    </div></>
  )
}

export default Contacts