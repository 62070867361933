import { useState } from 'react'


import { useNavigate } from 'react-router-dom'

import BackButton from '../../SMComponents/BackButton'

import { useDataContext } from "../../../Context/DataContext"
const Archive = () => {  
    const history = useNavigate()
  const { orders, setOrderAsDone } = useDataContext()
  const formatDateTime = (date) => {
    let d = new Date(date)
    return d.getDate() + '.' + parseInt(d.getMonth() + 1) + '.' + d.getFullYear()
  }
  return (
    <div style={{paddingTop: '100px', minHeight: "71vh"}}>
      <BackButton link="/admin" />
        <table className="table-responsive">
      <thead>
        <tr>
          <th>Číslo objednávky</th>
          <th>Datum dovezení</th>
          <th>Jméno</th>
          <th>Cena</th>
        </tr>
      </thead>
      <tbody>
      {(orders.orders)?.map((order) => { 
        if(order.isDone != 1) return 
        return <tr className='orderRow' onClick={() => history(`/archiveOrder/${order.id_order}`)} key={order.id_order}>
            <td className='orderRowDetail'>{order.id_order}</td>
            <td>{formatDateTime(parseInt(order.shipmentTime))} Kč</td>
            <td>{order.name}</td>
            <td>{order.price + order.priceOfDelivery} Kč</td>
        </tr>

      })}
      </tbody>
    </table>
    </div>
  )
}

export default Archive