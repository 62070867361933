import Mainpage from './Admin/Mainpage'

const Admin = () => {

  return (
  <div>
    <div className='text-dark' style={{paddingTop: '100px', minHeight: "71vh"}}>
      <Mainpage />
    </div>
  </div>
  )
}

export default Admin