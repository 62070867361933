import Carousel from "react-multi-carousel"
import 'react-multi-carousel/lib/styles.css';
import { Card, Button, Container } from "react-bootstrap"
import { useDataContext } from "../Context/DataContext"
import Item from "./Item";

import { useNavigate } from 'react-router-dom'

const FavouriteItems = ({ shownItemId = 0}) => {
    const history = useNavigate()
    const { items } = useDataContext()
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1400 },
            items: 4,
        },
        tablet: {
            breakpoint: { max: 1400, min: 1000 },
            items: 3,
        },
        Smallertablet: {
            breakpoint: { max: 1000, min: 700 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 700, min: 0 },
            items: 1,
        },
    }
    return (
      <Container>
        <Carousel
            removeArrowOnDeviceType={["superLargeDesktop", "desktop", "tablet", "mobile", "Smallertablet"]}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={6000}
            showDots={true}
            className="mb-3"
        >   
            {items?.map((item) => {
                if (item.isAvalible == 1 && item.tag_titles !== null && item.id_product != shownItemId) {
                    return (
                        <div className="card__center m-2" key={item.id_product} onClick={() => history(`/itemDetail/${item.id_product}`)} >
                            <Item item={item} />
                        </div>
                    )
                }
            })}
        </Carousel></Container>
    )
}

export default FavouriteItems
