import {useEffect, useRef, useState} from "react"

import {useNavigate} from 'react-router-dom'

import {useDataContext} from "../Context/DataContext"
import FavouriteItems from "./FavouriteItems"
import Contacts from "./Contacts"
import New from './New'
import {Button, Container} from "react-bootstrap"

import Onas from '../Images/onas.webp'

const LandingPage = () => {
    const [shownData, setShownData] = useState()
    const { items, news } = useDataContext()

    const history = useNavigate()
    
    const search = useRef()

    useEffect(() => {
        setShownData(items)
    }, [items])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <div style={{width: '100%', padding: '0'}}>
            <section
                id="hero"
                className="hero d-flex align-items-center section-bg"
                style={{backgroundColor: "hsl(0deg 0% 92%)", boxShadow: "0px 2px 25px rgba(0, 0, 0, 0.1)", height:"80vh"}}
            >
             
                    <div id='hero-header' className=" m-0 justify-content-center center d-flex gy-5">
                        <div id='hero-text' style={{margin:"0 auto"}} className="center order-2 order-lg-1 d-flex flex-column justify-content-center align-items-center text-center">
                            <h2
                                data-aos="fade-up"
                                className="aos-init aos-animate"
                                style={{whiteSpace: "pre-line"}}
                            >
                                Ovoce a zelenina 
                                <br /> 
                                Nejvyšší  kvality
                                <br />
                                Až k vám domů
                            </h2>
                            { <p
                                data-aos="fade-up"
                                data-aos-delay="100"
                                className="aos-init aos-animate"
                            >
                               Ovoce a zelenina té nejvyšší kvality může být před vašemi dveřmi již dva dny po objednání.
                               <br />
                                <p style={{ color: "red", marginBottom: 0, fontSize: 25, fontWeight: 550}}>Pouze Ústecký kraj!</p>
                                
                            </p> }
                            <div
                                className="d-flex aos-init"
                                data-aos="fade-up"
                                data-aos-delay="200"
                            >
                                {" "}
                                <a
                                    onClick={()=> {
                                        history('/shopPage')
                                        window.scrollTo(0, 0)
                                    }}
                                    className="btn-book-a-table"
                                    style={{cursor: 'pointer'}}
                                >
                                    Naše produkty
                                </a>
                            </div>
                        </div>

                    </div>

            </section>


            <br></br>

            <div className="favItemsLandingPage"><FavouriteItems/></div>


            <section
                id="about"
                className="about "

            >
                <div
                    className=" aos-init aos-animate "
                    data-aos="fade-up"
                >

                    <div className="row m-0 gy-4"
                         style={{paddingBottom: '25px', boxShadow: "0px 2px 25px rgba(0, 0, 0, 0.1)"}}>
                        <div className="section-header text-center">
                            <h2>Novinky</h2>
                        </div>

                        <Container className='row mx-0 justify-content-center flex-d'>
                            <New newsletter={news[0]}/>

                        </Container>

                        <div className={"text-center"}><Button
                            style={{backgroundColor: "hsl(98deg 89% 36%)", color: 'white', width: "fit-content"}}
                            className="border-0" onClick={() => history('/newsletter')}>Další novinky</Button></div>

                    </div>

                </div>
            </section>


            <section
                id="about"
                className="about "

            >
                <div
                    className=" aos-init aos-animate "
                    data-aos="fade-up"
                >
             
                    <div className="row m-0 gy-4" style={{paddingBottom: '25px', backgroundColor: "hsl(0deg 0% 92%)", boxShadow: "0px 2px 25px rgba(0, 0, 0, 0.1)"}}>
                    <div className="section-header text-center">
                        <h2>O nás</h2>
                        <p>
                            Zjistěte o nás <span className="odkaz" onClick={() => history('/aboutus')}>více</span>
                        </p>
                    </div>
                                    
                        <Container className='row m-0 justify-content-center flex-d'>
                        <div
                            id="about-img"
                            className="col-lg-4 position-relative about-img aos-init aos-animate"
                            style={{backgroundImage: `url(${Onas})`, backgroundSize: 'cover'}}
                            data-aos="fade-up"
                            data-aos-delay="150"
                        >
                            <div className="call-us position-absolute" style={{boxShadow: "0px 2px 25px rgba(0, 0, 0, 0.1)"}}>
                                <h4>Zavolejte nám</h4>
                                <p>+420 732 191 116</p>
                            </div>
                        </div>
                        <div
                            className="col-lg-3 aos-init aos-animate"
                            data-aos="fade-up"
                            data-aos-delay="300"
                        >
                            <div className="content ps-0 ps-lg-5">
                              
                                <ul>
                                </ul>
                                <p>
                                    {" "}
                                    Naše společnost se specializuje na rozvoz čerstvé zeleniny a ovoce přímo až k zákazníkovi. Máme široký sortiment zeleniny a ovoce od místních farmářů, kteří dbají na kvalitu a čerstvost produktů. Díky naší službě mohou zákazníci jednoduše objednat zeleninu a ovoce online a my jim ji doručíme až do domu nebo kanceláře. Naším cílem je poskytovat kvalitní a čerstvou zeleninu a ovoce, aby si naši zákazníci mohli užít zdravé a chutné jídlo. 
                                </p>
                                <b className="fst-italic">
                                    {" "}
                                    "Jsme hrdí na to, že podporujeme místní farmáře a pomáháme zlepšovat kvalitu stravování našich zákazníků."


                                </b>
                                <p>- Dominik Svoboda</p>
                                <div className="position-relative mt-4">
                                    {" "}
                                    <img
                                        src="assets/img/about-2.jpg"
                                        className="img-fluid"
                                        alt=""
                                    />
                                </div>
                            </div>
                                 </div>  
                        </Container>
                    </div>
                </div>
            </section>
          
     

            <Contacts /><br/>
        </div>
        
    )
}

export default LandingPage
