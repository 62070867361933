import { useRef } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useDataContext } from "../../../../Context/DataContext"

const Category = ({ category }) => {
  const newName = useRef()
  const file = useRef(0)

  
  const { apiLink, getCategories, setSelectedFile, editCategory, token } = useDataContext()

  const handleDelete = async () => {
    await fetch(apiLink + '/admin/deleteCategory', {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'x-auth-token': token},
      body: JSON.stringify(category)
    })
    getCategories()
  }

    const uploadImage = (uploadedFile) => {
        if(uploadedFile.type.includes('image')) return setSelectedFile(uploadedFile)
        alert('Soubor není obrázek!')
        file.current.value = ''
    }

    const handleEdit = async () => {
        await editCategory({category, name: newName.current.value})
        getCategories()
    }

  return (
    <Form>
          <div
              className="m-3 rounded p-3 text-dark"
              style={{
                  backgroundColor: "hsl(0deg 0% 92%)",
                  boxShadow: "rgb(0 0 0 / 10%) 0px 2px 25px",
              }}
          >
              <div className="text-center">
                  <div className="text-capitalize">
                      {/* <h1>{newsletter.title}</h1> */}
                      <Form.Group className="m-3">
                          <Form.Control
                              type="text"
                              ref={newName}
                              defaultValue={category.name}
                              required
                          />
                      </Form.Group>
                      {/* <h4>{formatDate(newsletter.timestamp)}</h4> */}
                      <img src={`${process.env.REACT_APP_IMAGES_LINK}${category.image}`} alt="" className='imgEditCategory' />
                      <Form.Control type="file" ref={file} name="sampleFile" onChange={(e) => { uploadImage(e.target.files[0]) }} required accept=".png, .jpg" />
                  </div>
              </div>
              {/* <p style={{whiteSpace: "pre-line"}}>{newsletter.message}</p> */}

              <Form.Group className="m-3 w-100">
              </Form.Group>
              <div className="d-flex justify-content-center w-100">
              <Button
                      className="border-0 mx-3"
                      onClick={handleEdit}
                  >
                      Upravit
                  </Button>
                  <Button
                      className="border-0 mx-3"
                      onClick={handleDelete}
                      disabled={category.id_category == 1}
                  >
                      Odebrat
                      
                  </Button>
              </div>
          </div>
      </Form>
  )
}

export default Category