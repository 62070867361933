import React, { useState, useEffect, useRef } from "react"

import { Card } from "react-bootstrap"

const Item = ({ item, tags, showTags }) => {
  return (<div
                className="col-md-12"
                style={{ width: "288px" }}
            >
                <Card
                    className={`text-dark m-2`}
                    style={{ backgroundColor: "hsl(0deg 0% 95%)" }}
                >
                    <div className="tags">
                    {tags.map((tag, index) => {
                        if(showTags.includes(tag)) return <span
                                key={tag.id_tag}
                                title={tag.title}
                                className="p-1"
                                style={{
                                    backgroundColor: `${tag.color}` 
                                }}
                            >
                                <>
                                    {tag.name}
                                </>
                            </span>
                    })}
                    </div>
                    <Card.Img
                        variant="top"
                        src={
                            `${process.env.REACT_APP_IMAGES_LINK}` + item.image
                        }
                        className="odkaz"
                        style={{ height: "18em", objectFit: "cover" }}
                    ></Card.Img>
                    <Card.Title className="p-2 text-left">
                        {item.name}
                    </Card.Title>

                    <div className="m-2 d-flex justify-content-between">
                        <div className="col-md-6">
                            <>
                                <b>{item.price.toFixed(2)} Kč </b>za{" "}
                                {item.amountType}
                            </>
                        </div>
                    </div>
                </Card>
            </div>)
}

export default Item