import {useEffect, useRef, useState} from 'react'
import {Alert, Button, Card, Form} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'

import DateTimePicker from 'react-datetime-picker';
import InputMask from 'react-input-mask';
import { useDataContext } from "../Context/DataContext"
import ShoppingCartItem from './ShoppingCartItem';
import { FcCheckmark } from 'react-icons/fc'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import useAsyncState from '../hook/useAsyncState'

const SendOrder = () => {
    const setDatos = () => {
        let d = new Date()
        d.setDate(d.getDate() + 2)
        d.setHours(0)
        return d
    }
    const history = useNavigate()
    const { shoppingCart, createOrder, err, setErr, apiLink, token } = useDataContext()
    const [dateTime, setDateTime] = useState(setDatos())
    const [minDate, setMinDate] = useState(new Date())

    const [deliveryPrice, setDeliveryPrice] = useState(0)
    const [promo_code, setPromo_code] = useState()
    const [price, setPrice] = useAsyncState(0)
    const [disabledDates, setDisabledDates] = useState([])

    let normalPrice = 0

    const name = useRef()    
    const surname = useRef()
    const email = useRef()
    const phoneNumber = useRef()
    const street =  useRef()
    const descriptionNumber = useRef()
    const city = useRef()
    const psc = useRef()
    const shop = useRef()
    const message = useRef()
    const promoCode = useRef()

    useEffect(() => {
        let d = new Date()
        d.setDate(d.getDate() + 2)
        d.setHours(0)
        d.setMinutes(0)
        d.setSeconds(0)
        d.setMilliseconds(0)
        if(disabledDates.includes(d)){
            console.lod(d)
        }
        setMinDate(d)
        setDateTime(d)
    }, []);

    const calculateShippingCost = async () => {
        fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${city.current.value}&key=${process.env.REACT_APP_GOOGLE_MAPS_API}`).then(res => res.json()).then(async (data) => {
            if (!data.results[0]) return;

            let location = data.results[0].geometry.location;
            let rochovLocation = {lat: 50.460671, lng: 14.118073};

            const response = await fetch(apiLink + '/distance', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({rochovLocation, location})
            })

            let distance = await response.json()
            setDeliveryPrice(distance.distance * 2.35)
            console.log(distance)
        })
    }
    
    const findHowManyDaysBetween = () => {
        let difference = dateTime - new Date()
        let totalDays = Math.ceil(difference / (1000 * 3600 * 24));
        return totalDays
    }

    const getDeliveryPrice = async () => { 
        const res = await fetch(apiLink + "/deliveryPrice")
        const data = await res.json()
        setDeliveryPrice(data.delivery_price)
    }

    useEffect(() => {
        // getDeliveryPrice()
    }, [])

    const handleSend = async () => {
        if(findHowManyDaysBetween() > 60) return setErr('Nemůžete si objednat tak daleko do budoucnosti! Limit je 60 dní.')
        if(shoppingCart.length === 0) return setErr("Musíte si něco přidat do košíku!")
        if((name.current.value).length === 0) return setErr('Vyplňte prosím svoje jméno!')
        if((surname.current.value).length === 0) return setErr('Vyplňte prosím svoje příjmení!')
        if(validateEmail(email.current.value) == null) return setErr('Napište existující E-mail!')
        if((phoneNumber.current.value).length !== 16) return setErr('Vyplňte prosím svoje telefonní číslo!')
        if((street.current.value).length === 0 ) return setErr('Vyplňte prosím svojí ulici!')
        if((descriptionNumber.current.value).length === 0) return setErr('Vyplňte prosím svoje číslo popisné!')
        if((city.current.value).length === 0) return setErr('Vyplňte prosím svoje město!')
        if((psc.current.value).length === 0) return setErr('Vyplňte prosím svoje psč!')
        /* if(dateTime.getHours() < 5 || dateTime.getHours() > 22) return setErr('Objednávat si můžete jen od 5:00 do 22:00!') */
        if(!shop.current.checked) return setErr('Musíte souhlasit s obchodními podmínkami!')
        setErr('')
        createOrder({
            name: name.current.value,
            surname: surname.current.value,
            email: email.current.value,
            address: `${street.current.value} ${descriptionNumber.current.value}, ${city.current.value}, ${psc.current.value}`,
            phoneNumber: phoneNumber.current.value,
            shipmentTime: Date.parse(dateTime),
            message: message.current.value,
            deliveryPrice,
            promo_code
        })
        window.scrollTo(0, 0)
        history('/orderCompleted')

    }

    const inputShit = () => {
        const re = /[0-9/]/g
        let result = [...descriptionNumber.current.value.matchAll(re)]
        descriptionNumber.current.value = result.toString().split(",").join("")
    }

    const validateEmail = (email) => {
        return String(email).toLowerCase().match( /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ )
    }

    const countPrice = async () => {
        let x = 0
        await shoppingCart.map(item => {
            x += item.quantity * item.price
        })
        let val = promoCode.current.value
        if(val.length > 0) {
            let res = await fetch(apiLink + '/promo_codes/apply_promo_code', {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'x-auth-token': token},
                body: JSON.stringify({code: val, price_of_products_in_cart: x})
            })

            let data = await res.json()
            if(!data?.code) {
                return setErr(data.message)
            }
            promoCode.current.value = `${data.code}           -${data.discount} Kč          ${data.discountPercentage}%`



            setPromo_code(data)
            setPrice(x - data.discount)
            setErr()
        }else { 
            setPrice(x)
        }
    }

    useEffect(() => {
        countPrice()
    }, [shoppingCart])

    useEffect(() => {
        const getDisabledDates = async () => {
            let res = await fetch(apiLink + '/disabledDates')
            let data = await res.json()
            setDisabledDates(data.map(function(item) {
                return new Date(item)
            }))
        }

        getDisabledDates()
    }, [])

    useEffect(() => {
        if(disabledDates.find(
            date => date.getTime() === dateTime.getTime(),
        )){
            let d = new Date(dateTime)
            d.setDate(d.getDate() + 1)
            setDateTime(d)
        }
        
    }, [disabledDates, dateTime])

    return (
        <div className={`d-flex justify-content-center`} style={{paddingTop: '100px', minHeight: "71vh"}}>
            <div className={`itemContainerSendOrder w-100 text-dark bg-light m-3`}>
                <Form className={`w-100`} onSubmit={(e) => e.preventDefault()} noValidate >
                    <div className='px-3' style={{paddingTop: '15px'}}>
                        {err && <Alert className='w-100' variant='danger'>{err}</Alert>}
                    </div>
                    <div className='d-flex w-100'>
                        <Form.Group className="mx-3 w-100">
                            <Form.Label className={"m-0"}>Jméno</Form.Label>
                            <Form.Control type="text" ref={name} maxLength={64} required/>
                        </Form.Group>
                        <Form.Group className="mx-3 w-100">
                            <Form.Label className={"m-0"}>Příjmení</Form.Label>
                            <Form.Control type="text" ref={surname} maxLength={64} required/>
                        </Form.Group>
                    </div>
                    <div className='d-flex w-100 mt-1'>
                        <Form.Group className="mx-3 w-100">
                            <Form.Label className={"m-0"}>E-Mail</Form.Label>
                            <Form.Control type="email" ref={email} maxLength={64} required/>
                        </Form.Group>
                        <Form.Group className="mx-3 w-100">
                            <Form.Label className={"m-0"}>Telefonní číslo</Form.Label>
                            <InputMask mask="+420 999 999 999" maskChar="" className='w-100 form-control' ref={phoneNumber} required/>
                        </Form.Group>
                    </div>
                    <div className='d-flex w-100 mt-1'>
                        <Form.Group className="mx-3 w-100">
                            <Form.Label className={"m-0"}>Ulice</Form.Label>
                            <Form.Control type="text" ref={street} maxLength={64} required/>
                        </Form.Group>
                        <Form.Group className="mx-3 w-100">
                            <Form.Label className={"m-0"}>Číslo popisné</Form.Label>
                            <Form.Control type="text" ref={descriptionNumber} onChange={inputShit} required/>
                        </Form.Group>
                    </div>
                    <div className='d-flex w-100 mt-1'>
                        <Form.Group className="mx-3 w-100">
                            <Form.Label className={"m-0"}>Město</Form.Label>
                            <Form.Control onChange={calculateShippingCost} type="text" ref={city} maxLength={64} required/>
                        </Form.Group>
                        <Form.Group className="mx-3 w-100">
                            <Form.Label className={"m-0"}>PSČ</Form.Label>
                            <InputMask mask="999 99" maskChar="" className='w-100 form-control' ref={psc} required/>
                        </Form.Group>
                    </div>
                    <div className='d-flex w-100'>
                        <Form.Group className="mx-3 w-100">
                            <Form.Label className={"m-0"}>Poznámka</Form.Label>
                            <Form.Control className='w-100' as="textarea" rows={2} style={{resize: "none"}}
                                          ref={message} maxLength={256}/>
                        </Form.Group>
                    </div>
                    <hr/>
                    <div className='d-flex w-100'>
                        <Form.Group className="mx-3 w-100">
                            <Form.Label className={"m-0"}>Dovést:</Form.Label>
                            <DatePicker selected={dateTime} onChange={setDateTime} format="dd/MM/y" minDate={minDate} excludeDates={disabledDates} className="w-100 rounded p-1" />
                        </Form.Group>
                    </div>
                    <hr/>
                    <div className='d-flex w-100'>
                        <Form.Group className="mx-3 w-100">
                            <Form.Label className={"m-0"}>Promo kod:</Form.Label>
                            <div className='d-flex'>
                                <Form.Control type="text" className='promocodeinput' ref={promoCode} disabled={promo_code?.code} />
                                {promo_code && <FcCheckmark size={35} className='mx-3' />}
                                <Button className='promocodebutton border-0' style={{backgroundColor: 'hsl(98deg 89% 36%)'}} onClick={countPrice} disabled={promo_code?.code}>Použít</Button>
                            </div>
                        </Form.Group>
                    </div>
                    <hr/>
                    <div className='w-100 px-3 paddingpls'>
                        <input id="check" type="checkbox" className='' ref={shop}/> <label htmlFor="check">Souhlasím s
                        obchodními <a href="/tos" target="_blank">podmínkami</a></label>
                        <Button style={{backgroundColor: 'hsl(98deg 89% 36%)'}} onClick={handleSend} className="w-100 border-0" type="submit">Odeslat Objednávku</Button>
                    </div>
                </Form>
                <div className='w-100'>
                    {shoppingCart && shoppingCart?.map((item) => {
                        normalPrice += item.quantity * item.price
                        return <ShoppingCartItem item={item} key={item.id_product} applyPromoCode={countPrice} />
                    })}
                    
                    {shoppingCart?.length === 0 &&
                        <div className='w-100 text-center'><br></br><h1>Košík je prázdný</h1></div>
                    }
                    {/* {promo_code && <div className='promoCodeContainer'>
                        <p className='promoCodeText'>{promo_code.code}</p>
                        <p className='promoCodeTextDiscount'>-{promo_code.discount} Kč ({promo_code.discountPercentage}%)</p>                        
                    </div>} */}
                    <div className={`m-3 bg-light text-center`}>
                        <hr className='mb-4 mt-0 d-inline-block mx-auto' style={{
                            width: "160px",
                            color: "rgb(70, 174, 10)",
                            backgroundColor: "rgb(70, 174, 10)",
                            height: "3px",
                            opacity: "1"
                        }}/>
                        <div>
                            <h2 >Doprava:  <b>{deliveryPrice != 0 ? ( deliveryPrice ).toFixed(2) + " Kč" : "Zdarma"}</b> </h2>
                            {promo_code?.code && <h2>Původní cena: <s><b>{(parseFloat(normalPrice) + parseFloat(deliveryPrice)).toFixed(2)} Kč</b></s> </h2>}
                            <h2>Celková cena: <b>{(parseFloat(price) + parseFloat(deliveryPrice)).toFixed(2)} Kč</b> </h2>
                            
                            <h4>Platba pouze v <strong>HOTOVOSTI</strong></h4>

                            <br></br>
                        </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default SendOrder