import { Form, Button, Alert } from 'react-bootstrap'
import { useRef, useState } from 'react'
import { useDataContext } from "../../../Context/DataContext"

import { useNavigate } from 'react-router-dom'
import BackButton from '../../SMComponents/BackButton'


const NewsletterAdd = () => {
  const history = useNavigate()
    const { addNewsletter } = useDataContext()
    const title = useRef()
    const message = useRef()
    const expirationDate = useRef()
    const [err, setErr] = useState('')
    const onClickSend = () => {
    
      if(title.current.value === '') return setErr("Musíte vyplnit svůj nadpis!")
      if(message.current.value === '') return setErr("Musíte napsat nějakou zprávu!")
      if(expirationDate.current.value === '') return setErr('Musíte zadat nějaký datum kdy novinka zmizí!')

      let json = {
          title: title.current.value,
          message: message.current.value,
          expirationDate: expirationDate.current.value
      }
      title.current.value = ''
      message.current.value = ''
      expirationDate.current.value = ''
      setErr('')
      addNewsletter(json)
      history('/admin')

    }
  return (
    <div style={{paddingTop: '100px', minHeight: "71vh"}}>
      
      <BackButton link="/admin" />
      <h1 className="text-center">Přidat novinku</h1>
      <Form>
        {err && <Alert variant='danger' className='m-3 w-100'>{err}</Alert>}
        <Form.Group className="m-3 w-100">
          <Form.Label>Nadpis</Form.Label>
          <Form.Control type="text" placeholder="Nadpis" ref={title} required />
        </Form.Group>
        <Form.Group className="m-3 w-100">
          <Form.Label>Kdy zmizí</Form.Label>
          <Form.Control type="date" min={new Date().toISOString().split("T")[0]} ref={expirationDate} required />
        </Form.Group>
        <Form.Group className="m-3 w-100">
          <Form.Label>Zpráva</Form.Label>
          <textarea className='w-100' style={{minHeight: '150px'}} maxLength={256} ref={message} />
        </Form.Group>
        <Button className="w-100 m-3" onClick={onClickSend} >Odeslat</Button>
    </Form>
    </div>
  )
}

export default NewsletterAdd