import React, { useState, useEffect, useRef } from "react"

import { AiFillFire } from "react-icons/ai"

import { useNavigate } from "react-router-dom"

import { IconContext } from "react-icons"
import { useDataContext } from "../Context/DataContext"
import { Card, Button } from "react-bootstrap"
const Item = ({ item }) => {
	const history = useNavigate()
	const {
		addIntoCart,
		listOfInCart,
		setInfo,
		setShowNavbar,
		items,
		shoppingCart,
		refresh,
	} = useDataContext()

	const quantity = useRef()

	const [amountInCart, setAmountInCart] = useState(0)
	const handleAddToCart = () => {
		addIntoCart({ item })
		setAmountInCart(1)
	}

	const getInShoppingCart = (id_product) => {
		let amount = shoppingCart.filter(item => {
		  return item.id_product == id_product
		})
		if(amount.length == 0) return 1
		return amount[0].quantity
	  }

	useEffect(() => {
		setAmountInCart(getInStorageFromCart(item.id_product))
	}, [])

	const handleChange = () => {
        if (quantity.current.value > items[getInStorage()].inStorage) quantity.current.value = items[getInStorage()].inStorage
        let q = 0
        if (item.amountType == "Ks") q = parseInt(quantity.current.value)
        if (item.amountType == "Kg") q = parseFloat(quantity.current.value).toFixed(1)
        addIntoCart({ item, quantity: q })
    }
	
	const getInStorage = () => {
		var index = items.findIndex((obj) => obj.id_product == item.id_product)
		return index
	}

	const btn = (nmbr) => {
		if (parseInt(quantity.current.value) + nmbr <= 0) return
		quantity.current.value = parseInt(quantity.current.value) + nmbr
		handleChange()
	}

	const getInStorageFromCart = (id_product) => {
		let product = shoppingCart.filter((item) => {
			return item.id_product === id_product
		})
		if (product[0]) return product[0].quantity
		else return 0
	}

	return (
		<>
			<div className="col mb-3 item">
				<div className="card h-100">
				<div className="tags">
                    {item.tags !== null && (item.tags.split(",")).map((tag_name, index) => {
                            return <span
                            key={index}
                            title={item.tag_titles.split(', ')[index]}
                            className="p-1"
                            style={{
                                backgroundColor: `${item.tag_colors.split(', ')[index]}` 
                            }}
                        >
                            <>
                                {tag_name}
                            </>
                        </span>
                        })}
                        </div>
					<img
						className="card-img-top imgShopPage odkaz"
						src={
							`${process.env.REACT_APP_IMAGES_LINK}` + item.image
						}
						alt="..."
						onClick={() => history(`/itemDetail/${item.id_product}`)}
					/>

					<div className="card-body p-4">
						<div className="text-center">
							<h5 className="fw-bolder">{item.name}</h5>
							{(item.price).toFixed(2)} Kč
						</div>
					</div>

					<div className="card-footer p-4 pt-0 border-top-0 bg-transparent">
						<div className="text-center">
							{listOfInCart.includes(item.id_product) ||
							amountInCart >= 1 ? (
								<>
									<div className="box">
										<div className="quantity d-flex align-top  justify-content-center">
											<span
												className="quantity-button"
												onClick={() => btn(-1)}
											>
												-
											</span>
											<input
                        type="number"
                        id="inputNum"
                        step={item.amountType === 'Kg' ? 0.1 : 1}
                        placeholder={item.quantity}
                        ref={quantity}
                        defaultValue={getInShoppingCart(item.id_product)}
                        onChange={handleChange}
                                min={item.amountType === 'Kg' ? 0.1 : 1}
                                max={item.inStorage}
                            />{" "}
											<span
												onClick={() => btn(1)}
												className="quantity-button"
											>
												+
											</span>
										</div>
									</div>
								</>
							) : item.inStorage > 0 ? (
								<Button
									onClick={handleAddToCart}
									className="rounded-0 border-0 text-light"
									style={{
										backgroundColor: "hsl(98deg 89% 36%)",
									}}
								>
									Do košíku
								</Button>
							) : (
								<span style={{ color: "hsl(98deg 89% 36%)" }}>
									Není skladem
								</span>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Item
