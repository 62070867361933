import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './MainComponents/App';
import "bootstrap/dist/css/bootstrap.min.css";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./index.css"
import './index.scss';
import { BrowserRouter as Router} from "react-router-dom"

import { DataProvider } from "./Context/DataContext"

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Router>
        <DataProvider>
            <App />
        </DataProvider>
    </Router>
);
