import React from 'react'
const AboutUs = () => {
  return (
<div className="container" style={{paddingTop:'100px', minHeight: '70vh'}}>

    
        <div className="section-header">
          <h2>O Nás</h2>
          <hr style={{width: "60px", color: "hsl(98deg 89% 36%)", backgroundColor: "hsl(98deg 89% 36%)", height: "3px", opacity: '1'}} className="mb-4 mt-0 d-inline-block mx-auto" />

        </div>
    
        <div id="about-img" className="row gy-4 m-0" style={{overflow:'hidden'}}>
          <div className="col-md-6 position-relative" >
      
            <div className="call-us position-absolute">
              <h4>Volejte!</h4>
              <p>+420 732 191 116</p>
            </div>
          </div>
          <div id="about-text" className="m-0 p-0 col-md-6 d-flex align-items-end">
            <div className="m-5 text-white content ps-0 ps-lg-5">
        
    
            <p>Jsme společnost specializující se na rozvoz čerstvého a kvalitního ovoce a zeleniny, přímo z místních farem, až k Vašim dveřím. V našem širokém sortimentu najdete nejlepší produkty, které jsou pečlivě vybírány od farmářů, kteří se starají o to, aby byly plné chuti a vitamínů. Naše online služba Vám umožňuje snadno objednat zdravé a chutné jídlo, které Vám doručíme přímo do Vaší domácnosti nebo kanceláře. Jsme tu proto, abychom Vám poskytli kvalitní ovoce a zeleninu, která Vám pomůže udržet zdraví a chuť k jídlu.</p>
    
 
            </div>
          </div>
        </div>
    <br/>
    <br/>
      </div>


     
   
  )
}

export default AboutUs