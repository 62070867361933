import { useRef, useEffect, useState } from "react"
import { useDataContext } from "../Context/DataContext"
import { Button } from 'react-bootstrap'
import { useParams, useNavigate } from 'react-router-dom'
import FavouriteItems from './FavouriteItems'
import BackButton from './SMComponents/BackButton'
const ItemDetail = () => {
    const { id_product } = useParams();
    const [item, setItem] = useState()
    const quantity = useRef()
    const { items, listOfInCart, shoppingCart, addIntoCart } = useDataContext()
    const [pridano, setPridano] = useState(true)

    const getInShoppingCart = (id_product) => {
      let amount = shoppingCart.filter(item => {
        return item.id_product == id_product
      })
      if(amount.length == 0) return 1
      return amount[0].quantity
    }

    useEffect(() => {
      if(!items) return

      const _item = items?.filter((__item) => {
        return __item.id_product == id_product
      })

      if(!_item) return

      setItem(_item[0])
    }, [items, id_product])

    useEffect(() => {
      if(!item) return 
      setPridano(listOfInCart.includes(item.id_product))
    }, [item, shoppingCart, listOfInCart])

    const handleChange = () => {
        if (quantity.current.value > items[getInStorage()].inStorage) quantity.current.value = items[getInStorage()].inStorage
        let q = 0
        if (item.amountType === "Kg") q = parseFloat(quantity.current.value).toFixed(1)
        else q = parseFloat(quantity.current.value)
        addIntoCart({ item, quantity: q })
    }

    const btn = (nmbr) => {
        if(parseFloat(quantity.current.value) + nmbr <= 0) return
        quantity.current.value = parseFloat(quantity.current.value) + nmbr
        handleChange()
    }

    const handleAddToCart = () => {
        setPridano(true)
        addIntoCart({ item })
    }

    const getInStorage = () => {
        let index = items.findIndex((obj) => obj.id_product == item.id_product)
        return index
    }

    useEffect(() => {
      window.scrollTo(0,0)
    }, [])

    if(!item) return (
      <div>
        <h1>Produkt neni</h1>
      </div>
    )

    return (
        <div style={{paddingTop: '75px'}}>
          <BackButton link="/shopPage" className="backButtonItem" />
          <section>
            <div className="container px-4 px-lg-5 my-5">
                <div className="row gx-4 gx-lg-5 align-items-center m-0">
                    <div className="col-md-6"><img className="card-img-top mb-5 mb-md-0 rounded imageonitemdetail" src={`${process.env.REACT_APP_IMAGES_LINK}/${item.image}`} alt="..." /></div>
                    <div className="col-md-6 text_item">
                        <h1 className="display-5 fw-bolder">{item.name}</h1>
                        <div className="fs-5 mb-5">
                            {/* <span className="text-decoration-line-through">$45.00</span> */}
                            <span>{(item.price).toFixed(2)} Kč za {item.amountType}</span>
                        </div>
                        <div className="fs-5 mb-5 tags-on-item">
                        {item.tags !== null && (item.tags.split(",")).map((tag_name, index) => {
                                return <span
                                key={index}
                                title={item.tag_titles.split(', ')[index]}
                                className="p-1"
                                style={{
                                    backgroundColor: `${item.tag_colors.split(', ')[index]}` 
                                }}
                            >
                                <>
                                    {tag_name}
                                </>
                            </span>
                            })}
                        </div>
                        <p className="lead" style={{whiteSpace: "pre-line"}}>{item.description}</p>
                        <div className="d-flex flex-row-reverse">
                            {/* <input className="form-control text-center me-3" id="inputQuantity" type="num" defaultValue="1" />
                            <button className="btn btn-outline-dark flex-shrink-0" type="button">
                                <i className="bi-cart-fill me-1"></i>
                                Add to cart
                            </button> */}

              {!pridano ? (
                <div >
                  <Button
                    disabled={
                      item.avalible == 0 ||
                      listOfInCart.includes(item.id_product)
                    }
                    className="rounded-0 border-0 text-light buyBtn"
                    onClick={handleAddToCart}
                    style={{
                      backgroundColor: "hsl(98deg 89% 36%)",
                    }}
                  >
                    {listOfInCart.includes(item.id_product)
                      ? "Přidáno do košíku"
                      : item.avalible == 0
                      ? "Není skladem"
                      : "Do košíku"}
                  </Button>
                </div>
              ) : (
                <>
                  <div className='box'>
                    <div className='quantity flex-row-reverse'>
                      <span className="quantityButtonDetail" onClick={() => btn(1)} >+</span>
                      <input
                        type="number"
                        className="inputQuantityDetail"
                        step={item.amountType === 'Kg' ? 0.1 : 1}
                        placeholder={item.quantity}
                        ref={quantity}
                        defaultValue={getInShoppingCart(item.id_product)}
                        onChange={handleChange}
                                min={item.amountType === 'Kg' ? 0.1 : 1}
                                max={item.inStorage}
                            />{" "}
                           
                        <span onClick={() => btn(-1)} className="quantityButtonDetail">-</span>
                         </div>  
                        </div>
                        
                        </>
                        )}


                        </div>
                    </div>
                </div>
            </div>
        </section>
          <div className="m-5"><FavouriteItems shownItemId={id_product} /></div>
        </div>
    )
}

export default ItemDetail
