import React from 'react'
import ControlPanel from './ControlPanel'
import ProductSellGraph from './Stats/ProductSellGraph'
import OrderStats from './Stats/OrderStats'

const Mainpage = () => {
  return (
    <div className='mainpageAdmin'>
        <div className='adminControlPanel'>
          <ControlPanel /> 
        </div>
        <div className='adminStats'>
          <ProductSellGraph />
        </div>
        {/* <OrderStats />  */}
    </div>
  )
}

export default Mainpage