import React from 'react'

import { useDataContext } from "../../../Context/DataContext"
import Category from './Components/Category'
import BackButton from '../../SMComponents/BackButton'

const DeleteCategory = () => {
  const { categories } = useDataContext()
  return (
    <div style={{paddingTop: '100px', minHeight: "71vh"}}>
    <BackButton link="/admin" />
        {categories?.map(category => {
          return <Category category={category} key={category.id_category} /> 
        })}
    </div>
  )
}

export default DeleteCategory