import { useState, useEffect, useRef } from "react"
import { useDataContext } from "../Context/DataContext"
import Categories from "./Categories"
import Item from "./Item"

const ItemShopPage = () => {
	const { items } = useDataContext()
	const [selectedCategory, setSelectedCategory] = useState(0)
	const [currentPage, setCurrentPage] = useState(1)
	const [itemsPerPage, setItemsPerPage] = useState(20)
	const search = useRef()
	const [searchValue, setSearchValue] = useState("")

	useEffect(() => {
    handleSearch()
  }, [selectedCategory, currentPage])

	const xddd = (index) => {
		setCurrentPage(index + 1)
		const element = document.getElementById("items")
		if (element) {
			element.scrollIntoView({ behavior: "smooth" })
		}
	}

	const handleChange = async (category) => {
		await setSelectedCategory(category.id_category)
		setCurrentPage(1)
		const element = document.getElementById("items")
		if (element) {
			element.scrollIntoView({ behavior: "smooth" })
		}
	}

	const handleSearch = () => {
		let val = search.current.value
		setSearchValue(val.toLowerCase())
		if (val.length > 0) setCurrentPage(1)
	}

	const filteredData = items.filter((el) => {
		if (el.isAvalible == 0) return
		if (selectedCategory == 0 || selectedCategory == 1) {
			return el.name.toLowerCase().includes(searchValue)
		} else {
			return (
				el.name.toLowerCase().includes(searchValue) &&
				el.id_category == selectedCategory
			)
		}
	})

	const indexOfLastItem = currentPage * itemsPerPage
	const indexOfFirstItem = indexOfLastItem - itemsPerPage

	const shownData = filteredData.slice(indexOfFirstItem, indexOfLastItem)

	const totalPages = Math.ceil(filteredData.length / itemsPerPage)

	return (
		<div style={{ paddingTop: "100px" }}>
      
			<div className='p-3 mx-4 shadow-p'>
				<input type="search" className="form-control" placeholder="Hledat" onChange={handleSearch} ref={search}/>
			</div>
			<div className="categoriosContainer"><Categories handleChange={handleChange} /></div>
			{totalPages !== 1 &&  <div className="d-flex justify-content-center mt-4 pb-3">
						{Array.from({ length: totalPages }).map((_, index) => (
							<button
								key={index}
								className={`pagination-button ${
									currentPage === index + 1 ? "active" : ""
								}`}
								onClick={() => xddd(index)}
							>
								{index + 1}
							</button>
						))}
					</div>}
			<section className="py-5" id="items">
				<div className="container px-4 px-lg-5">
					<div className="row gx-4 gx-lg-3 row-cols-1 row-cols-md-3 row-cols-xl-4 justify-content-center mx-1">
						{shownData?.map((item) => {
							if (item.isAvalible == 1) {
								return (
									<div key={item.id_product}>
										<Item
											item={item}
											id={`item-${item.id_product}`}
										/>
									</div>
								)
							}
						})}
					</div>
					{totalPages !== 1 &&  <div className="d-flex justify-content-center mt-4 pb-3">
						{Array.from({ length: totalPages }).map((_, index) => (
							<button
								key={index}
								className={`pagination-button ${
									currentPage === index + 1 ? "active" : ""
								}`}
								onClick={() => xddd(index)}
							>
								{index + 1}
							</button>
						))}
					</div>}
				</div>
			</section>
		</div>
	)
	{
		/* <div className="col-md-12 row m-0" style={{paddingTop:'100px'}}>
      <div className='col-md-3'>
        <Categories handleChange={handleChange} />
      </div>
      <div className='col-md-9'>
        <div className='p-3 bg-light shadow-p'>
          <input type="search" className="form-control" placeholder="Hledej" onChange={handleSearch} ref={search}/>
        </div>
        <div style={{background: 'hsl(0deg 0% 92%)'}} className='shadow-p col-md-12 d-flex justify-content-center flex-wrap m-0 w-100 mb-5' id="items">
          {shownData?.map((item) => {
            if(item.isAvalible == 1){
              return <div key={item.id_product} ><Item item={item} id={`item-${item.id_product}`} /></div>
            }
          })}
        </div>
        <div className="d-flex justify-content-center mt-4 pb-3">
          {Array.from({ length: totalPages }).map((_, index) => (
            <button
            key={index}
            className={`pagination-button ${
              currentPage === index + 1 ? 'active' : ''
            }`}
            onClick={() => xddd(index)}
          >
            {index + 1}
          </button>
          ))}
        </div>
      </div>
    </div> */
	}
}

export default ItemShopPage
