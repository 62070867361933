import React, { useRef, useState, useEffect } from 'react'

import { Alert, Button, Form } from 'react-bootstrap'
import { useParams } from 'react-router-dom'

import { useDataContext } from "../../../Context/DataContext"
import BackButton from '../../SMComponents/BackButton'

const AddCategory = () => {

    const { setSelectedFile, addCategory } = useDataContext()
    const file = useRef()
    const name = useRef()
    const handleSend = () => {
        addCategory({
            category: {
                name: name.current.value,
            }
        })
      /* addItem({
        item: {
          name: name.current.value,
          price: price.current.value,
          quantity: quantity.current.value,
          description: description.current.value,
          amountType: amountType.current.value
        }
      }) */
      file.current.value = ''
      name.current.value = ''
      window.scrollTo(0, 0)
    }

    const uploadImage = (uploadedFile) => {
      if(uploadedFile.type.includes('image')) return setSelectedFile(uploadedFile)
      alert('Soubor není obrázek!')
      file.current.value = ''

    }

  return (
    <div style={{paddingTop: '100px', minHeight: "71vh"}}>
    <BackButton link="/admin" />
      <h1 className="text-center">Přidat kategorii</h1>
      <Form className='w-100' onSubmit={(e) => e.preventDefault()}>
      <Form.Group className="m-3 w-100">
        <Form.Label>Jméno</Form.Label>
        <Form.Control type="text" placeholder="Jméno" ref={name} required />
      </Form.Group>
      <Form.Group className="m-3 w-100">
          <Form.Label>Obrazek</Form.Label>
          <Form.Control type="file" placeholder="E-mail" ref={file} name="sampleFile" onChange={(e) => { uploadImage(e.target.files[0]) }} required accept=".png, .jpg, .jpeg" />
        </Form.Group>      
        <Button onClick={handleSend} className="m-3 w-100" type="submit">Přidat</Button>
    </Form>
    </div>
  )
}

export default AddCategory