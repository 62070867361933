import { useEffect, useRef, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import DateTimePicker from 'react-datetime-picker/dist/DateTimePicker'
import { useDataContext } from '../../../Context/DataContext'
import BackButton from '../../SMComponents/BackButton'

/* const promo_code = {
    id_code: 1,
    code: "TEST10",
    discount: 10,
    max_usage: 50,
    used_count: 0,
    expiration_date: new Date("2023-07-19")
} */

const CreatePromoCode = () => {
  const { token, apiLink, history, getPromoCodes } = useDataContext()

  const code = useRef()
  const discount = useRef()
  const max_usage = useRef()
  const [expiration_date, setExpiration_date] = useState()
  const [minDate, setMinDate] = useState(new Date())

  useEffect(() => {
    let d = new Date()
    d.setDate(d.getDate() + 1)
    setMinDate(d)
    d.setDate(d.getDate() + 6)
    d.setHours(0)
    d.setMinutes(0)
    d.setSeconds(0)
    d.setMilliseconds(0)
    setExpiration_date(d)
  }, [])

  const handleSend = async () => {
    const promo_code = {
      code: code.current.value,
      discount: discount.current.value,
      max_usage: max_usage.current.value,
      expiration_date
    }

    await fetch(apiLink + '/admin/createPromoCode', {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'x-auth-token': token},
      body: JSON.stringify(promo_code)
    })
    getPromoCodes()
    history('/promoCodes')

  }

  const generateRandomCode = () => {
    code.current.value = makeid()
  }

  const makeid = (length = 12) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  return (
    <div style={{paddingTop: "100px", minHeight: "70vh"}} className=''>
      <BackButton link="/promoCodes" />
      <div className={`w-100 text-dark bg-light px-3 rounded`}>
      <Form className={`w-100`} onSubmit={(e) => e.preventDefault()}>
        <Form.Group className="w-100">
          <Form.Label className={"m-0"}>Kod</Form.Label>
          <div className='d-flex'>
            <Form.Control type="text" ref={code} maxLength={64} required />
            <Button onClick={generateRandomCode} className='mx-3 w-25' style={{backgroundColor: 'hsl(98deg 89% 36%)'}}>Vygenerovat random</Button>
          </div>
        </Form.Group>
        <Form.Group className="w-100">
          <Form.Label className={"m-0"}>Sleva %</Form.Label>
          <Form.Control type="text" ref={discount} maxLength={64} defaultValue={10} min={0} max={100} required/>
        </Form.Group>
        <Form.Group className="w-100">
          <Form.Label className={"m-0"}>Maximum pouziti (0 nekonecno)</Form.Label>
          <Form.Control type="text" ref={max_usage} maxLength={64} defaultValue={0} required/>
        </Form.Group>
        <Form.Group className="w-100">
          <Form.Label className={"m-0"}>Kdy vyprsi</Form.Label>
          <DateTimePicker onChange={setExpiration_date} value={expiration_date} className="w-100" minDate={minDate} disableClock={true} format="dd/MM/y"/>
        </Form.Group>
        <Button style={{backgroundColor: 'hsl(98deg 89% 36%)'}} onClick={handleSend} className="w-100 border-0 mt-3 mb-3" type="submit">Vytvorit kod</Button>

      </Form>
      </div>
    </div>
  )
}

export default CreatePromoCode