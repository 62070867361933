import React from 'react'
import { Navigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'

import { useDataContext } from "../Context/DataContext"

const PrivateRoute = ({ children, adminRequest = false, shoppingCartRequast = false, orderRequest = false}) => {
  const [cookies] = useCookies()
  const { shoppingCart, isAdmin, order } = useDataContext()
  if(adminRequest) return isAdmin || cookies.user ? children : <Navigate to="/login" />
  if(orderRequest) return order ? children : <Navigate to="/" />
  return children;
}

export default PrivateRoute