import React, { useRef } from 'react'
import { useDataContext } from '../../Context/DataContext'

const ChangeDeliveryPrice = () => {
    const { apiLink, token, history } = useDataContext()
    const newPrice = useRef()
    const setPrice = async () => {
        if(newPrice.current.value < 0) return alert('Cena dopravy musi bejt vic jak 0')
        if(!Number.isInteger(parseInt(newPrice.current.value))) return alert('neni to integer') 
        await fetch(apiLink + '/admin/setPrice', {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'x-auth-token': token},
            body: JSON.stringify({newPrice: newPrice.current.value})
        })

        history("/admin")
    }

    return (
        <div style={{paddingTop: "100px", minHeight: "70vh"}} className='changeDeliveryPriceContainer'>
            <h1>Změna ceny dopravy</h1>
            <div className='changeDeliveryPriceContainer'>
                <input type="number" min={0} ref={newPrice} className='changeDeliveryPriceInput' />
                <button onClick={setPrice} className='changeDeliveryPriceButton'>Použít</button>
            </div>

        </div>


)
}

export default ChangeDeliveryPrice