import React, {useEffect, useState} from 'react'
import axios from "axios";
import {useDataContext} from "../Context/DataContext";

const Gallery = () => {
    const [images, setImages] = useState([])
    const {apiLink} = useDataContext()

    const fetchImages = async () => {
        const response = await axios.get(`${apiLink}/gallery`)
        return response.data;

    }

    useEffect(() => {
        fetchImages().then(data => {
            setImages(data)
        })
    }, []);

    return (
        <div className="container" style={{paddingTop: '100px', minHeight: '70vh'}}>


            <div className="section-header">
                <h2>Galerie</h2>
                <hr style={{
                    width: "60px",
                    color: "hsl(98deg 89% 36%)",
                    backgroundColor: "hsl(98deg 89% 36%)",
                    height: "3px",
                    opacity: '1'
                }} className="mb-4 mt-0 d-inline-block mx-auto"/>
            </div>
            {console.log(images)}
            <div className="gallery">

                {images?.map((image, index) => {
                    return (
                        <a key={index} target="_blank" href={image.url}>
                            <img src={image.url} width={300} height={300} style={{
                                borderRadius: '10px',
                                boxShadow: '0 0 10px 0 rgba(0,0,0,0.2)',
                                margin: '10px',
                                objectFit: 'cover'
                            }}/>
                        </a>
                    )
                })

                }
            </div>


            <br/>
            <br/>
        </div>
    )


}

export default Gallery