const TagsList = ({ tags, addTag, showTags }) => {
  return (
    <div className='tagList'>
    <hr />
        {tags.map((tag, index) => {
            return <div key={tag.id_tag}>
                <div className='tagTagList'>
                <h6>{tag.name}</h6>
                <input type="checkbox" defaultChecked={showTags.includes(tag)} onChange={() => addTag(tag)} />
            </div>   
            <hr />
            </div>
        })}
    </div>
  )
}

export default TagsList