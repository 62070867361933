import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'

import { useDataContext } from "../../../../Context/DataContext"
import BackButton from '../../../SMComponents/BackButton'


const Order = () => {
    const history = useNavigate()
    const { orders, setOrderAsDone, apiLink, token } = useDataContext()
    const { id_order } = useParams();
    const [cancel, setCancel] = useState(false)

    const [order, setOrder] = useState()

    useEffect(() => {
      if(!orders) return

      const _order = (orders.orders)?.filter((__order) => {
        return __order.id_order == id_order
      })

      const orderItems = (orders.orderItems)?.filter((item) => {
        return item.id_order == id_order

      })

      if(!_order) return
      

      const json = {
        order: _order[0],
        orderItems
      }

      setOrder(json)
    }, [orders])

    const cancelOrder = async () => {
      await fetch(apiLink + '/admin/cancelOrder', {
          method: 'POST',
          headers: {'Content-Type': 'application/json', 'x-auth-token': token},
          body: JSON.stringify({id_user: order.order.id_order, id_order: order.order.id_order})
      })
      history('/orders')
    }

    const formatDateTime = (date) => {
      let d = new Date(date)
      return d.getDate() + '.' + parseInt(d.getMonth() + 1) + '.' + d.getFullYear()
    }

    if(!order) return (
      <div>
        <h1>Objednávka neni</h1>
      </div>
    )


  return (
    <div style={{paddingTop: '100px', minHeight: "71vh"}}>
        <BackButton link="/orders" />
        <div className='d-flex'>
          <div>
            <h3>Jméno:</h3>
            <h3>E-mail:</h3>
            <h3>Adresa:</h3>
            <h3>Tel. Číslo: </h3>
            {order.order.message !== "" && <h3>Zpráva:</h3>}
          </div>
          <div className='mx-2'>
            <h3>{order.order.name}</h3>
            <h3>{order.order.email}</h3>
            <h3>{order.order.address}</h3>
            <h3>{order.order.phoneNumber}</h3>
            {order.order.message !== "" && <h3>{order.order.message}</h3>}
          </div>
        </div>
        <table className="table-responsive">
      <thead>
        <tr>
          <th>Jméno</th>
          <th>Počet</th>
          <th>Cena</th>
        </tr>
      </thead>
      <tbody>
      {order.orderItems?.map((item) => { 
        return <tr key={item.id_product}>
            <td>{item.name}</td>
            <td>{item.quantity} {item.amountType}</td>
            <td>{item.price} Kč</td>
        </tr>

      })}
      <tr>
            <td colSpan={2}>Cena dohromady</td>
            <td>{order.order.price + order.order.priceOfDelivery} Kč</td>
        </tr>
      </tbody>
    </table>
    <div className='adminControlPanelTlacitka m-0 mt-3'>
      <Button className='buttonAdminControlPanel' onClick={() => setOrderAsDone(order.order)}>Hotovo</Button>
      <Button className='buttonAdminControlPanel' onClick={() => setCancel(!cancel)}>Zrušit</Button>
      <Button className='buttonAdminControlPanel' variant='danger' disabled={!cancel} onClick={() => cancelOrder()}>Opravdu?</Button>
    </div>
    </div>
  )
}

export default Order