import React, { useEffect, useRef, useState } from 'react';
import BackButton from '../../Components/SMComponents/BackButton';
import axios from 'axios';
import { useDataContext } from '../../Context/DataContext';
import { Button, Form, ProgressBar } from 'react-bootstrap';

const AdminGallery = () => {
    const { apiLink, token } = useDataContext();
    const file = useRef();
    const [selectedFile, setSelectedFile] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);

    const [gallery, setGallery] = useState([]);

    const handleSend = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        if (selectedFile) formData.append('sampleFile', selectedFile);
        formData.append('data', JSON.stringify({ name: selectedFile.name }));

        try {
            const response = await axios.post(apiLink + '/admin/uploadImage', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'x-auth-token': token,
                },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setUploadProgress(progress);
                },
            });
            console.log(response.data); // handle response accordingly
            setSelectedFile('');
            setUploadProgress(0); // reset progress after successful upload

            getGallery().then((data) => {
                setGallery(data);
            });
        } catch (error) {
            console.error('Error uploading file:', error);
            setUploadProgress(0); // reset progress in case of error
        }
    };

    const uploadImage = (uploadedFile) => {
        if (uploadedFile.type.includes('image')) {
            setSelectedFile(uploadedFile);
        } else {
            alert('Soubor není obrázek!');
            file.current.value = '';
        }
    };

    const getGallery = async () => {
        const response = await axios.get(apiLink + '/admin/getGallery', {
            headers: {
                'x-auth-token': token,
            },
        });
        return response.data;
    };

    const deleteImage = async (id) => {
        axios.post(apiLink + '/admin/deleteImage', { id }, {
            headers: {
                'x-auth-token': token,
            },
        }).then(() => {
            getGallery().then((data) => {
                setGallery(data);
            });
        });
    }

    useEffect(() => {
        getGallery().then((data) => {
            setGallery(data);
        });
    }, [token]);

    return (
        <div style={{ paddingTop: '100px', minHeight: '71vh' }}>
            <BackButton link="/admin" />
            {console.log(uploadProgress)}
            <Form>
                <Form.Group className="m-3 w-100">
                    <Form.Label>Obrazek</Form.Label>
                    <Form.Control
                        type="file"
                        placeholder="E-mail"
                        ref={file}
                        name="sampleFile"
                        onChange={(e) => {
                            uploadImage(e.target.files[0]);
                        }}
                        required
                        accept=".png, .jpg"
                    />
                </Form.Group>
                <Button onClick={handleSend} className="m-3 w-100" type="submit">
                    Přidat
                </Button>
                {uploadProgress > 0 && (
                    <><h1>Nahrávám...</h1><ProgressBar now={uploadProgress} label={`${uploadProgress}%`} className="m-3 w-100" style={{ height: "20px" }} /></>
                )}
            </Form>

            <table>
                <thead>
                    <tr>
                        <th>Obrazek</th>
                        <th>Smazat</th>
                    </tr>
                </thead>
                <tbody>
                    {gallery.map((item, index) => (
                        <tr key={index}>
                            <td>
                                <img src={item.url} alt={item.url} style={{ width: '100px' }} />
                            </td>
                            <td>
                                <Button onClick={() => deleteImage(item.id)}>
                                    Smazat
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default AdminGallery;
