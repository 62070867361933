import React from 'react'

import { useDataContext } from "../../../Context/DataContext"
import BackButton from '../../SMComponents/BackButton'
const DeleteTag = () => {
  const { deleteTag, tagsOnItems } = useDataContext()
  return (
    <div style={{ paddingTop: "100px", minHeight: "71vh" }}>
        <BackButton link="/admin" />
      {/* <div className='d-flex justify-content-center'>
        <input type="search" className="form-control w-75" placeholder="Hledej" onChange={handleSearch} ref={search}/>
      </div> */}
        <table className="table-responsive mt-3">
      <thead>
        <tr>
          <th>#</th>
          <th>Jméno</th>
          <th>Cena</th>
          <th>Trash</th>
        </tr>
      </thead>
      <tbody>
      {tagsOnItems?.map(tag => {
        return <tr key={tag.id_product}  >
          <td style={{width: '40px', backgroundColor: `${tag.color}`}}></td>
          <td>{tag.name}</td>
          <td>{tag.title}</td>
          <td style={{width: '15px' }}><button onClick={() => {deleteTag(tag)}}>Vymazat</button></td>
        </tr>
      })}
      </tbody>
    </table>
    </div>
  )
}

export default DeleteTag