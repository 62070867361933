import { useState, useEffect } from 'react'
import BackButton from '../../SMComponents/BackButton'
import TagsList from './EditTag/TagsList'
import Item from "./Components/Item"

import { useDataContext } from "../../../Context/DataContext"
import TagEditor from './EditTag/TagEditor'

const EditTag = () => {
  const { items, tagsOnItems, setTags } = useDataContext()
  const [shownItem, setItem] = useState()
  const [shownTag, setShownTag] = useState()

  const getRandomItem = () => {
      setItem(items[Math.floor(Math.random()*items.length)])
  }
  
  const displayTag = async (tag) => {
    let xx = shownTag
    xx.name = tag.name
    xx.title = tag.title
    xx.color = tag.color
    await setShownTag(null)
    await setShownTag(xx)
  }

  useEffect(() => {
      if(!items) return
      getRandomItem()
  }, [items])

  return (
    <div style={{ paddingTop: "100px", minHeight: "71vh" }}>
        <BackButton link="/admin" />
        <button onClick={() => getRandomItem()}>Refresh Item</button>
        <div className="TagCreator">
            {tagsOnItems && shownItem && <Item tags={tagsOnItems} item={shownItem} showTags={[shownTag]}/>}
            <TagEditor shownTag={shownTag} displayTag={displayTag} setShownTag={setShownTag} />
            <TagsList tags={tagsOnItems} shownTag={shownTag} setShownTag={setShownTag} />
        </div>
    </div>
  )
}

export default EditTag