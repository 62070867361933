import { useEffect } from 'react'
import { useDataContext } from "../Context/DataContext"
import ShoppingCartItem from './ShoppingCartItem';
import { FiTrash2 } from 'react-icons/fi'
import { FiShoppingCart } from 'react-icons/fi'
import { Card } from 'react-bootstrap'
import {useState} from 'react';
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
const ShoppingCart = ({ handleLeave }) => {    

  const history = useNavigate()
  const { shoppingCart, removeWholeItemFromShoppingCart } = useDataContext()
  const handleClick = () => {
      window.scrollTo(0, 0)
      history('/sendOrder')
  }

  useEffect(() => {
    if(shoppingCart.length === 0) return handleLeave()
  }, [shoppingCart.length]);

  return (
    <div style={{height:'600px', overflow:'auto'}} onMouseLeave={handleLeave}>
    <div style={{height:'50px', backgroundColor:'hsl(0deg 0% 98%)'}}><h4 className='p-3'><b>Váš košík</b></h4>
    </div>

      {shoppingCart && shoppingCart?.map((item) => {
        return <div className='container' style={{overflow:'hidden', backgroundColor:'hsl(0deg 0% 98%)',width: '400px'}} key={item.id_product}>

    <div className='row col-md-12 m-0'>
    <div className='col-md-3'>     
      <img className='img-fluid mb-2 mt-3' src={`${process.env.REACT_APP_IMAGES_LINK}` + item.image}></img>

      </div> 
      <div className='col-md-3'>
      <p className='mb-2 mt-3'>{item.quantity} {item.amountType === "Ks" ? "x" : "Kg"}</p>

      </div>

      <div className='col-md-5'>     
      <p className='mb-4 mt-3'>{item.name}</p>

                  <b ><h4 className='mb-2 mt-3'>{Math.round(item.quantity * item.price * 100) / 100 + ' Kč'}</h4></b>
      </div> 
        <div className='col-md-1'>
                  <FiTrash2 className='mb-2 me-5 mt-3' onClick={() => removeWholeItemFromShoppingCart(item)} style={{cursor: 'pointer'}} color={'hsl(98deg 89% 36%)'} />

        </div>
              </div>
              <hr/>
            
          </div>
      })}
      <div className='text-end' style={{height:'50px', backgroundColor:'hsl(0deg 0% 98%)'}}>
      
      {shoppingCart?.length > 0 && <span className="m-5 odkaz" onClick={handleClick} to="/sendOrder">
                                    <Button className="rounded-0 border-0 text-light" style={{backgroundColor: "hsl(98deg 89% 36%)"}}>Přejít do košíku</Button>
                                </span>}
                                </div>
    </div> 
  )
}

export default ShoppingCart