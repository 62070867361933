import { useDataContext } from "../../../Context/DataContext"
import { Alert } from 'react-bootstrap'
import New from './Components/New'
import BackButton from "../../SMComponents/BackButton"

const Editnewsletter = () => {
    const { news, info } = useDataContext()

  return (
    <div style={{paddingTop: '100px', minHeight: "71vh"}}>
      
      <BackButton link="/admin" />
        {info && <Alert variant="success" className="m-3">{info}</Alert>}
        {news?.map(newsletter => {
            return <New key={newsletter.id_newsletter} newsletter={newsletter} />
        })}  
    </div>
  )
}

export default Editnewsletter