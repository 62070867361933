import { Container } from 'react-bootstrap'
import Header from './Header';
import Mainpage from './Mainpage';

import { useDataContext } from "../Context/DataContext"
import Footer from './Footer';

const  App = () => {
  const { showNavbar } = useDataContext()

  return (
    <div style={{ minHeight: "100vh", backgroundColor: "hsl(0deg 0% 85%)"}} >
        {showNavbar && <Header />}
        <Mainpage />
  

      <Footer />
      </div>
  
  );
}

export default App;
