import React, { useRef, useEffect } from 'react'

import { useDataContext } from "../../../../Context/DataContext"

const TagEditor = ({ shownTag, displayTag, setShownTag }) => {
  const name = useRef(), color = useRef(), title = useRef()
  const { editTag } = useDataContext()
  
  const update = () => {
    editTag(shownTag)
    setShownTag(null)
  }

  const onChange = () => {
    displayTag({name: name.current.value, color: color.current.value, title: title.current.value})
  }

  useEffect(() => {
    if(!shownTag) return
    name.current.value = shownTag.name
    color.current.value = shownTag.color
    title.current.value = shownTag.title
  }, [shownTag])

  if(!shownTag) return (
    <div className='creatorBody'>
      <input type="text" placeholder='name' />
      <input type="text" placeholder='title' />
      <input type="color" placeholder='color' />
      <button onClick={update}>Upravit</button>
    </div>
  )

  return (
      <div className='creatorBody'>
        <input type="text" placeholder='name' ref={name} onChange={onChange} defaultValue={shownTag.name} />
        <input type="text" placeholder='title' ref={title} onChange={onChange} defaultValue={shownTag.title} />
        <input type="color" placeholder='color' ref={color} onChange={onChange} defaultValue={`#${shownTag.color}`} />
        <button onClick={update}>Upravit</button>
      </div>
  )
}

export default TagEditor