import { useRef } from "react"
import { Card } from "react-bootstrap"
import { FiTrash2 } from "react-icons/fi"
import { useDataContext } from "../Context/DataContext"

import { useNavigate } from 'react-router-dom'

const ShoppingCartItem = ({ item, applyPromoCode }) => {
    const history = useNavigate()
    const { addIntoCart, removeWholeItemFromShoppingCart, items, } = useDataContext()
    const handleChange = () => {
      if (quantity.current.value < 0) quantity.current.value = 1
      if (quantity.current.value < 0.1 && item.amountType === 'Kg') quantity.current.value = 0.1
        if (quantity.current.value > items[getInStorage()].inStorage)
            quantity.current.value = items[getInStorage()].inStorage
        let q = 0
        if (item.amountType === "Kg")
            q = parseFloat(quantity.current.value).toFixed(1)
        else q = parseInt(quantity.current.value)
        addIntoCart({ item, quantity: q })
    }

    const quantity = useRef()

    const getInStorage = () => {
        var index = items.findIndex((obj) => obj.id_product == item.id_product)
        return index
    }

    const formatInStorage = (nmbr) => {
        return nmbr > 1000 ? '1000+' : nmbr 
    }
    const btn = (nmbr) => {
        if(parseInt(quantity.current.value) + nmbr <= 0) return
        quantity.current.value = parseInt(quantity.current.value) + nmbr
        handleChange()
        applyPromoCode()
    }
    return (
        <div
            className={`bg-light text-dark m-3`}
            key={item.id_product}
        >
            {items && <Card.Body className="row m-0 col-md-12">
                <div className="col-md-4">
                    <img
                        className="img-fluid"
                        src={
                            `${process.env.REACT_APP_IMAGES_LINK}` + item.image
                        }
                        style={{
                            cursor: 'pointer'
                        }}
                        onClick={() => history(`/itemDetail/${item.id_product}`)}
                    ></img>
                </div>
                <div className="col-md-8">
                    <h1>
                        {item.name} {item.quantity} {item.amountType === "Ks" ? "x" : "Kg"}
                    </h1>
                    <p>
                        {item.amountType == 'Ks' ?  <b>Cena za jeden kus: {item.price} Kč</b> : <b>Cena za jeden Kg: {item.price} Kč</b>}
                    </p>
                    <p>
                        <b>
                            Na skladě: {formatInStorage(items[getInStorage()]?.inStorage)}{" "}
                            {item.amountType === "Ks" ? "Ks" : "Kg"}
                        </b>
                    </p>
                    <div className="d-flex justify-content-between">
                        <div style={{ fontSize: "10px" }}>
                        <div className='box'>
                    <div className='quantity'>
                      <span className="quantity-button" onClick={() => btn(-1)} >-</span>
                      <input
                        id='inputNum'
                        type="number"
                        step={item.amountType === 'Kg' ? 0.1 : 1}
                        placeholder={item.quantity}
                        ref={quantity}
                        onChange={handleChange}
                                min={item.amountType === 'Kg' ? 0.1 : 1}
                                max={item.inStorage}
                                defaultValue={item.quantity}
                            />{" "}
                           
                        <span onClick={() => btn(1)} className="quantity-button">+</span>
                         </div>   
                        </div>
                            
                          
                        </div>
                        <div className="d-flex align-items-center">
                            <b>
                                {(
                                    (item.quantity * item.price * 100) /
                                    100
                                ).toFixed(2) + " Kč"}
                            </b>
                        </div>
                        
                        <div className="d-flex align-items-center" >
                        <FiTrash2
                            onClick={() =>
                                removeWholeItemFromShoppingCart(item)
                            }
                            style={{ cursor: "pointer" }}
                            color={"hsl(98deg 89% 36%)"}
                        />
                        </div>
                    </div>
                </div>
            </Card.Body>}
        </div>
    )
}

export default ShoppingCartItem
