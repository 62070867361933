import React, { useContext, useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import fetch from 'node-fetch'
import { useNavigate } from 'react-router-dom'
import ShoppingCart from '../Components/ShoppingCart'

import { FiShoppingCart } from 'react-icons/fi'
const DataContext = React.createContext()

export function useDataContext(){
    return useContext(DataContext)
}

export function DataProvider({ children }){
    const history = useNavigate()
    const [cookies, setCookies, removeCookies] = useCookies()
    const [shoppingCart, setShoppingCart] = useState([])
    const [err, setErr] = useState('')
    const [info, setInfo] = useState('') 
    const [order, setOrder] = useState()
    const [listOfInCart, setListOfInCart] = useState([])
    const [categories, setCategories] = useState()
    const [showNavbar, setShowNavbar] = useState(true)

    const [numberOfItemsInCart, setNumberOfItemsInCart] = useState(0)
    const [users, setUsers] = useState()
    const [tags, setTags] = useState([])
    const [tagsOnItems, setTagsOnItems] = useState([])
    const [isAdmin, setIsAdmin] = useState(false)
    const [user, setUser] = useState()
    const [orders, setOrders] = useState([])
    const [selectedFile, setSelectedFile] = useState();
    const [items, setItems] = useState([])
    const [news, setNews] = useState([])
    const [token, setToken] = useState()
    const [promo_codes, setPromo_codes] = useState()
    const apiLink = process.env.REACT_APP_API_LINK 

    const [stats, setStats] = useState()

    const getToken = async (_user) => {
        let res = await fetch(apiLink + '/auth', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(_user)
        })
        let data = await res.json()
        setToken(data.token)
    }

    const [width, setWidth] = useState(window.innerWidth);

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        setCookies('shoppingCart', shoppingCart)
        setCookies('listOfInCart', listOfInCart)
    }, [shoppingCart])

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    
    useEffect(() => {
        setTimeout(() => {
            setInfo('')
        }, 10000)
    }, [info])
    
    const isMobile = width <= 1000;

    const getItems = async () => {
        let res = await fetch(apiLink + '/mainpageItems', {
            headers: {'Content-Type': 'application/json', 'x-auth-token': token},
        })
        let data = await res.json()
        setItems(data)
        return data
    }

    const getPromoCodes = async () => {
        let res = await fetch(apiLink + '/admin/promoCodes', {
            headers: {'Content-Type': 'application/json', 'x-auth-token': token},
        })
        let data = await res.json()
        setPromo_codes(data)
        return data
    }

    const getNews = async () => {
        let res = await fetch(apiLink + '/newsletter', {
            headers: {'Content-Type': 'application/json', 'x-auth-token': token},
        })
        let data = await res.json()
        setNews(data)
    }

    const getCategories = async () => {
        let res = await fetch(apiLink + '/categories', {
            headers: {'Content-Type': 'application/json', 'x-auth-token': token},
        })
        let data = await res.json()
        setCategories(data)
    }

    const getStats = async () => {
        let res = await fetch(apiLink + '/admin/stats', {
          headers: {'Content-Type': 'application/json', 'x-auth-token': token},
        })
    
        let json = await res.json()
    
        setStats(json)
    }

    const getUsers = async () => {
        let res = await fetch(apiLink + '/admin/admins', {
            headers: {'Content-Type': 'application/json', 'x-auth-token': token},
          })
      
          let json = await res.json()
      
          setUsers(json)
    }

    const getTags = async () => {
        let res = await fetch(apiLink + '/admin/tags', {
            headers: {'Content-Type': 'application/json', 'x-auth-token': token},
        })
        
        let json = await res.json()

        setTagsOnItems(json)

        let x = json.slice()
        x.unshift({
            id_tag: 0,
            name: "Novy tag",
            color: "#ff0000",
            title: "Novy tag"
        })
        setTags(x)
        
    }
    useEffect(() => {
        if(token) {
            getTags()
            getOrders()
            getStats()
            getUsers()
            getPromoCodes()
        }
    }, [token]);

    useEffect(() => {
        getNews()
        getItems()
        getCategories()
    }, []);

    useEffect(() => {
        if(cookies.user){
            setUser(cookies.user)
            setIsAdmin(true)
            getToken(cookies.user)
        }
        if(cookies.shoppingCart){
            setShoppingCart(cookies.shoppingCart)
            setListOfInCart(cookies.listOfInCart)
        }
    }, [])

    useEffect(() => {
        calculateInCart()
    }, [shoppingCart])
    
    const getInStorage = (item) => {
        var index = items.findIndex(obj => obj.id_product == item.id_product)
        return index
    }

    const calculateInCart = () => {
        let number = 0
        shoppingCart.map((item) => {
            number += parseFloat(item.quantity)
        })
        setNumberOfItemsInCart(number)
    }

    const addIntoCart = async ({item, quantity = 1}) => {
        if(quantity < 0) return
        let checkIfInCart = () => {
            return new Promise(resolve => {
                shoppingCart.map(crt => {
                    if(crt.id_product === item.id_product) {
                        return resolve(true)
                    }
                }) 
                return resolve(false)
            }) 
        }

        setListOfInCart([...listOfInCart, item.id_product])
        
        let field = shoppingCart
    
        let itemToAdd = {
            index: shoppingCart.length,
            id_product: item.id_product,
            name: item.name,
            inStorage: item.inStorage,
            quantity: 1.00,
            price: item.price,
            amountType: item.amountType,
            image: item.image
        }
    
        if(!(await checkIfInCart()) && shoppingCart.length == 0) {
            return setShoppingCart([itemToAdd])
        }
        if(!(await checkIfInCart())){
            return setShoppingCart([...shoppingCart, itemToAdd])
        }
    
        let dadada = shoppingCart.map((crt, index) => {
            if(crt.id_product === item.id_product) {
                if(quantity == 0) {}
                else if(quantity <= items[getInStorage(itemToAdd)].inStorage) return field[index] = {...crt, quantity }
            }
            return crt
        }) 
        
        await setShoppingCart(dadada)
        calculateInCart()
    }

    const removeFromShoppingCart = (data) => {
        let arr
        arr = shoppingCart.filter((item) => {
            if(item.id_product === data.id_product && item.quantity > 1) return item.quantity -= 1
            return item.index !== data.index
        })
        setShoppingCart(arr)
        removeFromListInCart(data.id_product)
        calculateInCart()

    }

    const removeWholeItemFromShoppingCart = (data) => {
        let arr = shoppingCart.filter((item) => {
            return item.index !== data.index
        })
        removeFromListInCart(data.id_product)
        setShoppingCart(arr)
        calculateInCart()

    }

    const removeFromListInCart = async (number) => {
        await setListOfInCart(listOfInCart.filter((num) => num !== number))
    }

    const createOrder = async (data) => {
        let dataToSend = {user: data, shoppingCart: shoppingCart}
        checkIfAllItemsAreInStock(shoppingCart)
        await setOrder(dataToSend)
        await fetch(apiLink + '/user/createOrder', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(dataToSend)
        })
        setShoppingCart([])
        setListOfInCart([])
        getItems()
    }

    const checkIfAllItemsAreInStock = async (data) => {
        let itms = await getItems()
        let d = data.map((crt) => {
            var index = itms.findIndex(obj => obj.id_product == crt.id_product);
            if(itms[index].inStorage < crt.quantity) setErr('Nemáme na skladě dostatek věcí které jste si chtěli koupit změnily jsme na počet který máme.')
            if(itms[index].inStorage === 0) removeWholeItemFromShoppingCart(crt)
        })
    }

    const login = async (data) => {
        let res = await fetch(apiLink + '/admin/login', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({email: data.email, password: data.password})
        })
        let json = await res.json()
        if(json.done) {
            setErr('')
            await setUser(json.user)
            await getToken(json.user)
            setCookies('user', json.user)
            await setIsAdmin(true)
            window.scrollTo(0, 0)
            return history('/admin')
        }
        return setErr(json.message)
    }

    const logout = () => {
        setUser(null)
        removeCookies('user')
        setIsAdmin(false)
        return history('/admin')
    }

    const createAdmin = async (data) => {
        fetch(apiLink + '/admin/createNewAdmin', {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'x-auth-token': token},
            body: JSON.stringify({email: data.email, username: data.username})
        })
        setInfo('Nový admin byl vytvořen.')
    }

    const getOrders = async () => {
        let res = await fetch(apiLink + '/admin/orders', {
            headers: {'Content-Type': 'application/json', 'x-auth-token': token},
        })

        let json = await res.json()

        setOrders(json)
    }

    const setOrderAsDone = async (order) => {
        await fetch(apiLink + '/admin/setOrderAsDone',  {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'x-auth-token': token},
            body: JSON.stringify({order})
        })
        getOrders()
    }

    const changeInfoAboutItem = async (data) => {
        const formdata = new FormData();
        if(selectedFile) formdata.append('sampleFile', selectedFile)
        formdata.append('data', JSON.stringify(data))
        await fetch(apiLink + '/admin/changeInfoAboutItem', {
            method: 'POST',
            headers: { 
                "encType": "multipart/form-data",
                'x-auth-token': token
            },
            body: formdata
        })
        setInfo('Produkt změněn.')
        setSelectedFile('')
        await getItems()
    }

    const addItem = async (data) => {
        const formdata = new FormData();
        if(selectedFile){
            formdata.append('sampleFile', selectedFile)
            formdata.append('data', JSON.stringify(data.item))
            await fetch(apiLink + '/admin/createItem', {
                method: 'POST',
                headers: { 
                    "encType": "multipart/form-data",
                    'x-auth-token': token
                },
                body: formdata
            })
          }
        setInfo('Položka byla přidána')
        setSelectedFile('')
        getItems()
    }

    const changeVisibilityOfItem = async (data) => {
        await fetch(apiLink + '/admin/changeVisibility', {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'x-auth-token': token},
            body: JSON.stringify({data})
        })
        getItems()
    }

    const sendForm = (data) => {
        fetch(apiLink + '/sendForm', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        })
    }

    const addNewsletter = async (data) => {
        await fetch(apiLink + '/admin/addNewsletter', {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'x-auth-token': token},
            body: JSON.stringify(data)
        })
        getNews()
    }

    const starItem = async (data) => {
        await fetch(apiLink + '/admin/starItem', {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'x-auth-token': token},
            body: JSON.stringify(data)
        })
        getItems()
    }

    const fullDelete = async (data) => {
        await fetch(apiLink + '/admin/fullDelete', {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'x-auth-token': token},
            body: JSON.stringify(data)
        })
        getItems()
    }

    const changePasswd = async (data) => {
        let dataToSend = {
            user: user,
            newPass: data
        }
        await fetch(apiLink + '/admin/changePasswd', {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'x-auth-token': token},
            body: JSON.stringify(dataToSend)
        })
        setInfo('Heslo bylo změněno.')
    }

    const editNewsletter = async (data) => {
        await fetch(apiLink + '/admin/editNewsletter', {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'x-auth-token': token},
            body: JSON.stringify(data)
        })
        getNews()
        setInfo(`Novinka s id: ${data.newsletter.id_newsletter} byla upravena.`)
    }
    
    const handleShoppingCartClick = () => {
        history('/sendOrder') // hide the shopping cart preview
    }

    const showShoppingCart = (e, handleLeave) => {
      if(isMobile) return
        if(shoppingCart.length > 0) document.getElementById("shoppingCartIcon").style = "opacity: 0;"
        return <div onMouseLeave={handleLeave} style={{
            position: 'fixed',
            zIndex: 999,
            top: document.getElementById('shoppingCartIcon').getBoundingClientRect().y - 10 ,
            left: document.getElementById('shoppingCartIcon').getBoundingClientRect().x - 250,
        }}>
            <div style={{height: '50px'}}>
                <FiShoppingCart color={'hsl(98deg 89% 36%)'} size={'25px'} className="odkaz" onClick={handleShoppingCartClick} style={{
                    position: 'fixed',
                    zIndex: 999,
                    top: document.getElementById('shoppingCartIcon').getBoundingClientRect().y,
                    left: document.getElementById('shoppingCartIcon').getBoundingClientRect().x,
                }} />
            </div>
            <ShoppingCart handleLeave={handleLeave} />
        </div>
    }

    const deleteNew = async (data) => {;
        await fetch(apiLink + '/admin/deleteNewsletter', {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'x-auth-token': token},
            body: JSON.stringify(data)
        })
        getNews()
    }

    const addCategory = async (data) => {
        const formdata = new FormData();
        if(selectedFile){
            formdata.append('sampleFile', selectedFile)
            formdata.append('data', JSON.stringify(data.category))
            await fetch(apiLink + '/admin/createCategory', {
                method: 'POST',
                headers: { 
                    "encType": "multipart/form-data",
                    'x-auth-token': token
                },
                body: formdata
            })
          }
        setInfo('Kategorie byla přidána')
        setSelectedFile('')
        getItems()
    }

    const editCategory = async (data) => {
        const formdata = new FormData();
        if(selectedFile) formdata.append('sampleFile', selectedFile)
        formdata.append('data', JSON.stringify(data))
        await fetch(apiLink + '/admin/editCategory', {
            method: 'POST',
            headers: { 
                "encType": "multipart/form-data",
                'x-auth-token': token
            },
            body: formdata
        })
        setInfo('Produkt změněn.')
        setSelectedFile('')
        await getCategories()
    }

    const deleteAdmin = (_user) => {
        if(!user.superAdmin) return alert('Nemůžeš provést tuto funkci!!')
    }

    const addTag = async (data) => {
        await fetch(apiLink + '/admin/addTag', {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'x-auth-token': token},
            body: JSON.stringify(data)
        })
        await getItems()
        await getTags()
    }
    
    const editTag = async (data) => {
        await fetch(apiLink + '/admin/editTag', {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'x-auth-token': token},
            body: JSON.stringify(data)
        })
        await getItems()
        await getTags()
    }

    const deleteTag = async (data) => {
        await fetch(apiLink + '/admin/deleteTag', {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'x-auth-token': token},
            body: JSON.stringify(data)
        })
        await getItems()
        await getTags()
    }

    const applyCoupon = async (code, price) => {
        let res = await fetch(apiLink + '/promo_codes/apply_promo_code', {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'x-auth-token': token},
            body: JSON.stringify({code, price_of_products_in_cart: price})
        })

        let data = await res.json()
    }

    const value = {
        items,
        addIntoCart,
        shoppingCart,
        removeFromShoppingCart,
        removeWholeItemFromShoppingCart,
        createOrder,
        login,
        err,
        isAdmin,
        orders,
        setOrderAsDone,
        createAdmin,
        info,
        changeInfoAboutItem,
        getItems,
        setSelectedFile,
        addItem,
        changeVisibilityOfItem,
        order,
        sendForm,
        addNewsletter,
        news,
        starItem,
        fullDelete,
        changePasswd,
        listOfInCart,
        editNewsletter,
        setErr, 
        setInfo,
        showShoppingCart,
        deleteNew,
        categories,
        addCategory,
        logout,
        setShowNavbar,
        showNavbar,
        apiLink,
        numberOfItemsInCart,
        getOrders,
        getCategories,
        editCategory,
        token,
        stats,
        users,
        setUsers,
        deleteAdmin,
        tags,
        setTags,
        addTag,
        tagsOnItems,
        editTag,
        deleteTag,
        history,
        applyCoupon,
        promo_codes,
        getPromoCodes
    }

    return (
        <DataContext.Provider value={value}>
            {children}
        </DataContext.Provider>
    )
}