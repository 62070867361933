import React, {useRef, useState} from 'react';
/* import { MDBIcon } from 'mdbreact'; */

import Logo1 from '../Images/snappedlogo.png'
import Logo2 from '../Images/dejgol.png'
import {useNavigate} from "react-router-dom";
import {useDataContext} from "../Context/DataContext";


const Footer = () => {
    const history = useNavigate()
    const {sendForm, setInfo, info} = useDataContext()
    const email = useRef()
    const phoneNumber = useRef()
    const message = useRef()
    const [sent, setSent] = useState(false)
    const [err, setErr] = useState('')
    const onClickSend = () => {
        if (email.current.value === '') return setErr("Musíte vyplnit svůj E-Mail!")
        if (phoneNumber.current.value === '') return setErr("Musíte vyplnit svoje telefonní číslo!")
        if (message.current.value === '') return setErr("Musíte napsat nějakou zprávu!")
        if (validateEmail(email.current.value) == null) return setErr('Napište existující E-mail!')

        let json = {
            email: email.current.value,
            phoneNumber: phoneNumber.current.value,
            message: message.current.value
        }
        sendForm(json)
        setInfo('Zpráva odeslána!')
        setSent(true)
        history('/')
        email.current.value = ""
        phoneNumber.current.value = ""
        message.current.value = ""
        window.scrollTo(0, 0)
        setErr('')
    }

    const validateEmail = (email) => {
        return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    }
    return (
        <>
            <div className="footer pt-3" style={{backgroundColor: "hsl(0deg 0% 92%)"}}>
                <div className={"footer-content d-flex justify-content-around align-items-center mx-5"}>
                    <div className={"px-5 text-center"}>
                        <a href='https://www.dejgol.cz'>
                            <img className="img-fluid" src={Logo2} height="200" width="200" alt="logo"/>
                        </a>
                        <p>Sponzor</p>

                        <a href='https://www.crudex.cz'>
                            <img className="img-fluid" src={Logo1} height="200" width="200" alt="logo"/>
                        </a>
                        <p>Sponzor a web design</p>
                    </div>

                    <div
                        className={"px-5 text-center d-flex justify-content-between align-content-between flex-column gap-4"}>
                        <a style={{color: "rgb(70, 174, 10)"}}
                           href="https://www.facebook.com/profile.php?id=100089871309657" role="button">
                            <i className="fab fa-facebook-f"></i> Facebook
                        </a>
                        <a style={{color: "rgb(70, 174, 10)"}} href="https://www.instagram.com/farma_rochov/"
                           role="button">
                            <i className="fab fa-instagram"></i> Instagram
                        </a>
                        <a style={{color: "rgb(70, 174, 10)"}} href='/tos' className='mx-3'>Obchodní podmínky</a>
                        <a style={{color: "rgb(70, 174, 10)"}} href='/reclamation' className='mx-3'>Reklamační řád</a>
                        <a style={{color: "rgb(70, 174, 10)"}} href='/gdpr' className='mx-3'>Zásady ochrany osobních
                            údajů</a>
                    </div>

                    <div className={"px-5"}>
                        <div className="text-center">

                            <ul className="list-unstyled mb-0">
                                <li><i style={{color: 'hsl(98deg 89% 36%)'}}
                                       className="fas fa-map-marker-alt fa-2x"></i>
                                    <p>Rochov 12, 413 01, Rochov</p>
                                </li>

                                <li><i style={{color: 'hsl(98deg 89% 36%)'}} className="fas fa-phone mt-4 fa-2x"></i>
                                    <p>+420 732 191 116</p>
                                </li>

                                <li><i style={{color: 'hsl(98deg 89% 36%)'}} className="fas fa-envelope mt-4 fa-2x"></i>
                                    <p>dominik.svoboda@crudex.cz</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='text-center p-3' style={{backgroundColor: "hsl(0deg 0% 92%)"}}>
                    © 2023 Copyright:
                    <a style={{color: "rgb(70, 174, 10)"}} className='odkaz' href='https://farmarochov.cz/'
                       target="_blank">
                        farmarochov.cz
                    </a>
                </div>

            </div>

        </>

        // </MDBFooter>
    );
}

export default Footer