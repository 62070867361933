import { useRef } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useDataContext } from "../../../../Context/DataContext"

const New = ({ newsletter }) => {
    const { editNewsletter, deleteNew } = useDataContext()

    const newTitle = useRef()
    const newExpirationDate = useRef()
    const newMessage = useRef()

    const formatDate = (date) => {
        let d = new Date(date)
        return d.getUTCDate() + '.' + ('0' + parseInt(d.getUTCMonth()+1)).slice(-2) + '.' + d.getUTCFullYear()
    }

    const defaultDate = (date) => {
        let d = new Date(date)
        return new Date(d.setDate(d.getDate() + 1)).toISOString().substring(0, 10)
    }

    const sendNewDate = () => {
        let json = {
            new: {
                newTitle: newTitle.current.value,
                newExpirationDate: newExpirationDate.current.value,
                newMessage:newMessage.current.value 
            },
            newsletter
        }

        editNewsletter(json)

    }

    const handleDelete = () => {
        deleteNew(newsletter)
    }
  return (
      <Form>
          <div
              className="m-3 rounded p-3 text-dark"
              style={{
                  backgroundColor: "hsl(0deg 0% 92%)",
                  boxShadow: "rgb(0 0 0 / 10%) 0px 2px 25px",
              }}
          >
              <div className="text-center">
                  <div className="text-capitalize">
                      {/* <h1>{newsletter.title}</h1> */}
                      <Form.Group className="m-3">
                          <Form.Control
                              type="text"
                              defaultValue={newsletter.title}
                              ref={newTitle}
                              required
                          />
                      </Form.Group>
                      {/* <h4>{formatDate(newsletter.timestamp)}</h4> */}
                      <Form.Group className="m-3">
                          <Form.Control
                              type="date"
                              min={new Date().toISOString().split("T")[0]}
                              defaultValue={defaultDate(
                                  newsletter.expirationDate
                              )}
                              ref={newExpirationDate}
                              required
                          />
                      </Form.Group>
                  </div>
              </div>
              {/* <p style={{whiteSpace: "pre-line"}}>{newsletter.message}</p> */}

              <Form.Group className="m-3 w-100">
                  <textarea
                      className="w-100"
                      style={{ minHeight: "150px" }}
                      defaultValue={newsletter.message}
                      ref={newMessage}
                  />
              </Form.Group>
              <div className="d-flex justify-content-center w-100">
                  <Button
                      className="border-0 mx-3"
                      onClick={sendNewDate}
                  >
                      Upravit
                  </Button>
                  <Button
                      className="border-0 mx-3"
                      onClick={handleDelete}
                  >
                      Odebrat
                  </Button>
              </div>
          </div>
      </Form>
  )
}

export default New