import React from 'react'

import { useNavigate } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'
import { Button } from 'react-bootstrap'

const BackButton = ({ link }) => {
const history = useNavigate()
  return (
    <div className='backButtonContainer'>
          <button onClick={() => history(link)} className='backButton btn'> <IoIosArrowBack /> Zpět</button>
    </div>
  )
}

export default BackButton