import React, { useEffect, useState, useRef } from 'react'
import { useDataContext } from "../../../../Context/DataContext"
import {  Button, Form } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import BackButton from '../../../SMComponents/BackButton';

const ItemInQuantity = () => {
    const history = useNavigate()
    const { id_product } = useParams();
    const inStorage = useRef()
    const price = useRef()
    const amountType = useRef()
    const file = useRef(0)
    const title = useRef()
    const description = useRef()
    const category = useRef()
    const [item, setItem] = useState()
    const { changeInfoAboutItem, changeVisibilityOfItem, starItem, fullDelete, setSelectedFile, categories, items, tagsOnItems, apiLink, token, getItems } = useDataContext()

    const getItem = () => {
        let _item = items.filter((el) => {
            return el.id_product == id_product
        })
        setItem(_item[0])
    }

    const update = () => {
        price.current.value = item.price
        inStorage.current.value = item.inStorage
        title.current.value = item.name
        description.current.value = item.description
    }

    useEffect(()=> {
        getItem()
    }, [items])

    useEffect(() => {
        if(item == undefined) return
        update()
    }, [item])

    const handleSubmit = async () => {
        await changeInfoAboutItem({item, newPrice: price.current.value, newInStorage: inStorage.current.value, newAmountType: amountType.current.value, newName: title.current.value, newDesription: description.current.value, newCategory: category.current.value ,name: item.name})
        history('/editItems')
    }

    const handleDelete = () => {
        changeVisibilityOfItem(item)
    }

    const _fullDelete = () => {
        fullDelete(item)
    }

    const uploadImage = (uploadedFile) => {
        if(uploadedFile.type.includes('image')) return setSelectedFile(uploadedFile)
        alert('Soubor není obrázek!')
        file.current.value = ''
    }

    const changeTag = async (id_tag) => {
        await fetch(apiLink + `/admin/changeTag`, {
            method: "POST",
            headers: {'Content-Type': 'application/json', 'x-auth-token': token},
            body: JSON.stringify({
                id_product: parseInt(id_product),
                id_tag
            })
        })
        getItems()
    }

    if(!item) return
    
    return (
        <div style={{paddingTop: '100px', minHeight: "71vh"}}>
        <BackButton link="/editItems" />
        <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Group className="m-3">
                <Form.Label>Změnit nazev</Form.Label>
                <Form.Control type="text" ref={title} placeholder={item.name} />
            </Form.Group>
            <Form.Group className="m-3">
                <Form.Label>Změnit kategorii</Form.Label>
                <select className="form-select w-100" ref={category} defaultValue={item.id_category}>
                    {categories?.map((category) => {
                        return <option value={category.id_category} key={category.id_category}>{category.name}</option>
                    })}
                </select>
            </Form.Group>
            <Form.Group className="m-3">
                <Form.Label>Změnit popisek</Form.Label>
                <textarea className='w-100' style={{minHeight: '150px'}} ref={description} />
            </Form.Group>
            <Form.Group className="m-3">
                <Form.Label>Změnit image</Form.Label>
                <br />
                <img src={`${process.env.REACT_APP_IMAGES_LINK}` + item.image} style={{height: "15em", objectFit: "cover", marginBottom: "15px"}} alt="" />
                <Form.Control type="file" ref={file} name="sampleFile" onChange={(e) => { uploadImage(e.target.files[0]) }} required accept=".png, .jpg" />
            </Form.Group>
            <Form.Group className="m-3">
                <Form.Label>Změnit počet na skladě</Form.Label>
                <Form.Control type="number" ref={inStorage} placeholder={item.inStorage} />
            </Form.Group>
            <Form.Group className="m-3">
                <Form.Label>Změnit cenu</Form.Label>
                <Form.Control type="number" ref={price}  placeholder={item.price} />
            </Form.Group>
            <Form.Group className="m-3">
                <Form.Label>Změnit veličinu množství</Form.Label>
                <select className="form-select w-100" ref={amountType} defaultValue={item.amountType}>
                    <option value="Ks">Kusy</option>
                    <option value="Kg" >Kila</option>
                </select>
            </Form.Group>

            {tagsOnItems && tagsOnItems.map(tag => {
                return  <div key={tag.id_tag}>
                <div className='tagTagList'>
                <h6>{tag.name}</h6>
                <input type="checkbox" defaultChecked={item.tags ? item.tags.includes(tag.name) : false} onChange={() => {changeTag(tag.id_tag)}} />
            </div>   
            <hr />
            </div>
            })}

            <Button className="w-100 border rounded-0" onClick={handleSubmit}>Potvrdit</Button>
            <Button className="w-100 border rounded-0 mt-1" onClick={handleDelete}>{item.isAvalible == 1 ? "Odebrat" : "Zobrazit"}</Button>
            <Button className="w-100 border rounded-0 mt-1" onClick={_fullDelete} disabled={item.isAvalible == 1}>Úplně odebrat</Button>
        </Form> 
</div>
    )
}

export default ItemInQuantity