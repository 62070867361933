import React from 'react'

const New = ({newsletter}) => {
    const formatDate = (date) => {
        let d = new Date(date)
        return ('0' + d.getUTCDate()).slice(-2) + '.' + ('0' + parseInt(d.getUTCMonth()+1)).slice(-2) + '.' + d.getUTCFullYear()
    }

    if(!newsletter) return

  return (
      <div className="m-3 rounded p-3 w-75 text-dark" style={{
          backgroundColor: 'hsl(0deg 0% 92%)',
          overflow: 'auto',
          borderBottom: '3px solid rgb(70, 174, 10)',
          boxShadow: 'rgb(0 0 0 / 10%) 0px 2px 25px'
      }}>
          <h1>{newsletter.title}</h1>
          <hr/>
          <p style={{whiteSpace: "pre-line"}}>{newsletter.message}</p>
          <div className={"d-flex justify-content-end"}><small>{formatDate(newsletter.timestamp)}</small></div>
      </div>
  )
}

export default New