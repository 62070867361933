import React from 'react'
import {Routes, Route} from "react-router-dom"
import LandingPage from '../Components/LandingPage'
import PrivateRoute from '../Components/PrivateRoute'
import SendOrder from '../Components/SendOrder'
import Admin from '../Components/Admin'
import Archive from '../Components/Admin/Order/Archive'
import Login from '../Components/Login'
import Orders from '../Components/Admin/Order/Orders'
import OrderCompleted from '../Components/OrderCompleted'
import ItemShopPage from '../Components/ItemShopPage'
import Newsletter from '../Components/Newsletter'
import AboutUs from '../Components/AboutUs'
import TOS from '../Components/TOS'
import GDPR from '../Components/GDPR'
import Editnewsletter from '../Components/Admin/News/Editnewsletter'

import {Container} from 'react-bootstrap'
import Reclamation from '../Components/Reclamation'
import Order from '../Components/Admin/Order/Components/Order'
import OrderArchive from '../Components/Admin/Order/Components/OrderArchive'
import ChangeQuantity from '../Components/Admin/Product/ChangeQuantity'
import ItemInQuantity from '../Components/Admin/Product/Components/ItemInQuantity'
import AddProduct from '../Components/Admin/Product/AddProduct'
import NewsletterAdd from '../Components/Admin/News/NewsletterAdd'
import AddCategory from '../Components/Admin/Category/AddCategory'
import ManageCategories from '../Components/Admin/Category/ManageCategories'
import AddAdmin from '../Components/Admin/User/AddAdmin'
import ChangePass from '../Components/Admin/User/ChangePass'
import ItemDetail from '../Components/ItemDetail'
import CreateTag from '../Components/Admin/Tags/CreateTag'
import EditTag from '../Components/Admin/Tags/EditTag'
import DeleteTag from '../Components/Admin/Tags/DeleteTag'
import ChangeDeliveryPrice from '../Components/Admin/ChangeDeliveryPrice'
import NotFound from '../Components/NotFound'
import CreatePromoCode from '../Components/Admin/Promo/CreatePromoCode'
import PromoCodes from '../Components/Admin/Promo/PromoCodes'
import SetDisabledDays from '../Components/Admin/SetDisabledDays'
import Gallery from "../Components/Gallery";
import AdminGallery from "../Components/Admin/AdminGallery";

const Mainpage = () => {
    return (
        <Routes>
            <Route exact path="/" element={<LandingPage/>}/>
            <Route path="/sendOrder" element={<Container><SendOrder/> </Container>}/>
            <Route path="/admin"
                   element={<Container><PrivateRoute adminRequest={true}><Admin/> </PrivateRoute> </Container>}/>
            <Route path="/orders"
                   element={<Container><PrivateRoute adminRequest={true}><Orders/> </PrivateRoute> </Container>}/>
            <Route path="/history"
                   element={<Container><PrivateRoute adminRequest={true}><Archive/> </PrivateRoute> </Container>}/>


            <Route path="/order/:id_order"
                   element={<Container><PrivateRoute adminRequest={true}><Order/> </PrivateRoute> </Container>}/>
            <Route path="/editItem/:id_product"
                   element={<Container><PrivateRoute adminRequest={true}><ItemInQuantity/> </PrivateRoute>
                   </Container>}/>
            <Route path="/archiveOrder/:id_order"
                   element={<Container><PrivateRoute adminRequest={true}><OrderArchive/> </PrivateRoute> </Container>}/>
            <Route path="/editItems"
                   element={<Container><PrivateRoute adminRequest={true}><ChangeQuantity/> </PrivateRoute>
                   </Container>}/>
            <Route path="/addProduct"
                   element={<Container><PrivateRoute adminRequest={true}><AddProduct/> </PrivateRoute> </Container>}/>
            <Route path="/addNewsletter"
                   element={<Container><PrivateRoute adminRequest={true}><NewsletterAdd/> </PrivateRoute>
                   </Container>}/>
            <Route path="/editNewsletter"
                   element={<Container><PrivateRoute adminRequest={true}><Editnewsletter/> </PrivateRoute>
                   </Container>}/>
            <Route path="/addCategory"
                   element={<Container><PrivateRoute adminRequest={true}><AddCategory/> </PrivateRoute> </Container>}/>
            <Route path="/manageCategories"
                   element={<Container><PrivateRoute adminRequest={true}><ManageCategories/> </PrivateRoute>
                   </Container>}/>
            <Route path="/addAdmin"
                   element={<Container><PrivateRoute adminRequest={true}><AddAdmin/> </PrivateRoute> </Container>}/>
            <Route path="/changePassword"
                   element={<Container><PrivateRoute adminRequest={true}><ChangePass/> </PrivateRoute> </Container>}/>
            <Route path="/createTag"
                   element={<Container><PrivateRoute adminRequest={true}><CreateTag/> </PrivateRoute> </Container>}/>
            <Route path="/editTag"
                   element={<Container><PrivateRoute adminRequest={true}><EditTag/> </PrivateRoute> </Container>}/>
            <Route path="/deleteTag"
                   element={<Container><PrivateRoute adminRequest={true}><DeleteTag/> </PrivateRoute> </Container>}/>
            <Route path="/changeDeliveryPrice"
                   element={<Container><PrivateRoute adminRequest={true}><ChangeDeliveryPrice/> </PrivateRoute>
                   </Container>}/>
            <Route path="/createPromoCode"
                   element={<Container><PrivateRoute adminRequest={true}><CreatePromoCode/> </PrivateRoute>
                   </Container>}/>
            <Route path="/promoCodes"
                   element={<Container><PrivateRoute adminRequest={true}><PromoCodes/> </PrivateRoute> </Container>}/>
            <Route path="/setDisabledDays"
                   element={<Container><PrivateRoute adminRequest={true}><SetDisabledDays/> </PrivateRoute>
                   </Container>}/>
            <Route path="/adminGallery"
                   element={<Container><PrivateRoute adminRequest={true}><AdminGallery/> </PrivateRoute> </Container>}/>


            <Route path="/editNewsletter"
                   element={<Container><PrivateRoute adminRequest={true}><Editnewsletter/> </PrivateRoute>
                   </Container>}/>
            <Route path="/login" element={<Container><Login/> </Container>}/>
            <Route path="/orderCompleted"
                   element={<Container><PrivateRoute orderRequest={true}><OrderCompleted/></PrivateRoute>
                   </Container>}/>
            <Route path="/shopPage" element={<Container><ItemShopPage/> </Container>}/>
            <Route path="/itemDetail/:id_product" element={<Container><ItemDetail/> </Container>}/>
            {/* <Route path="/contacts" element={<Container><Contacts /> </Container>} /> */}
            <Route path="/newsletter" element={<Container><Newsletter/> </Container>}/>
            <Route path="/aboutus" element={<Container><AboutUs/> </Container>}/>
            <Route path="/gallery" element={<Container><Gallery/> </Container>}/>
            <Route path="/tos" element={<Container><TOS/> </Container>}/>
            <Route path="/gdpr" element={<Container><GDPR/> </Container>}/>
            <Route path="/reclamation" element={<Container><Reclamation/> </Container>}/>


            <Route path='*' element={<NotFound/>}/>


        </Routes>
    )
}

export default Mainpage