import React, { useRef } from 'react'
import { Alert, Button, Form } from 'react-bootstrap'
import { useDataContext } from "../../../Context/DataContext"
import BackButton from '../../SMComponents/BackButton'

const AddAdmin = () => {
  const { createAdmin, err } = useDataContext()
  
  const email = useRef()
  const username = useRef()

  const handleSend = () => {
    if(!email.current.value || !username.current.value) return 
    createAdmin({email: email.current.value, username: username.current.value})
    email.current.value = ''
    username.current.value = ''
    window.scrollTo(0, 0)
  }
  return (
    <div style={{paddingTop: '100px', minHeight: "71vh"}}>
      
    <BackButton link="/admin" />
      <h1 className='text-center'>Přidat Admina</h1>
      <Form className='w-100' onSubmit={(e) => e.preventDefault()}>
      <Form.Group className="m-3 w-100">
        <Form.Label>Jméno</Form.Label>
        <Form.Control type="text" placeholder="Jméno" ref={username} required />
      </Form.Group>
        <Form.Group className="m-3 w-100">
          <Form.Label>E-mail</Form.Label>
          <Form.Control type="text" placeholder="E-mail" ref={email} required />
        </Form.Group>
        
        <Button onClick={handleSend} className="m-3 w-100" type="submit">Přidat</Button>
    </Form>
    </div>
  )
}

export default AddAdmin