import {useState} from 'react'

import {useDataContext} from "../Context/DataContext"
import {Button} from 'react-bootstrap'
import New from './New'

const Newsletter = () => {
    const { news } = useDataContext()
    const [showAll, setShowAll] = useState(false)

    /* const checkIfUpToDate = (date) => {
        let today = new Date()
        let postDate = new Date(Date.parse(date))
        today.setHours(0,0,0)
        postDate.setHours(0,0,0)
        if(parseInt(today.getTime()) <= parseInt(postDate.getTime())) return true
        return false
    } */

    return (
        <div className="container d-flex justify-content-center flex-column align-items-center"
             style={{paddingBottom: '100px', paddingTop: "100px", minHeight: '70vh'}}>
            <h1 className='text-center'>Novinky</h1>
            <div className='text-center'>
                <hr style={{
                    width: "60px",
                    color: "hsl(98deg 89% 36%)",
                    backgroundColor: "hsl(98deg 89% 36%)",
                    height: "3px",
                    opacity: '1'
                }} className="mb-4 mt-0 d-inline-block mx-auto"/>
            </div>
            {showAll && news.map((newsletter, index) => {
                return <New key={newsletter.id_newsletter} newsletter={newsletter}/>
            })}

            {!showAll && news?.map((newsletter, index) => {
                return (index < 3 && 
                    <New key={newsletter.id_newsletter} newsletter={newsletter} />
                )
            })}
            {!showAll && <div className='w-100 d-flex justify-content-center'><Button style={{backgroundColor: "hsl(98deg 89% 36%)", color: 'white'}} className="border-0" onClick={() => setShowAll(true)}>Starší novinky</Button></div>}
        </div>
    )
}

export default Newsletter