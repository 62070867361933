import React from 'react'

import { useDataContext } from "../Context/DataContext"

const OrderCompleted = () => {
    const { order } = useDataContext()
    const formatDateTime = (date) => {
        let d = new Date(date)
        return d.getDate() + '.' + parseInt(d.getMonth() + 1) + '.' + d.getFullYear()
    }
    let price = 0
    order.shoppingCart.map(item => {
        price += item.price * item.quantity
    })
  return (
    <div style={{paddingTop:'100px', paddingBottom:'3%', minHeight: '70vh'}} className='text-dark w-100 bg-white rounded'>
        <div className='d-flex justify-content-start ms-5 col-md-12'>
            <h1><b>Potvrďte svojí objednávku</b></h1>
        </div>
        <div className='container-fluid'>
        <hr/>
        </div> 
        <div className='row col-md-12 m-0'>
            <div  className='rounded container-fluid col-md-5'>
                <div className='d-flex w-100 ms-5 mt-5'>
                    <h5 style={{color: "grey"}}>Jméno:</h5></div>
                <div className='ms-5'>
                    <h3><b>{order.user.name + " "}{order.user.surname} </b></h3>
                </div>
                <div className='d-flex w-100 ms-5'>
                    <h5 style={{color: "grey"}}>Přivezeme: </h5>
                </div>
                <div className='ms-5'>
                    <h3><b>{formatDateTime(order.user.shipmentTime) }</b></h3>
                </div>
        
                <div className='d-flex w-100 ms-5'>
                    <h5 style={{color: "grey"}}>E-mail:</h5>
                    </div>
                    <div className='ms-5'>
                    <h3><b>{order.user.email +" "}</b></h3>
                    
                </div>
              
                <div className='d-flex w-100 ms-5'>
                    <h5 style={{color: "grey"}}>Telefonní číslo: </h5>
                </div>
                <div className='ms-5'>
                    <h3><b>{order.user.phoneNumber}</b></h3>
                </div>
               
                <div className='d-flex w-100 ms-5'>
                    <h5 style={{color: "grey"}}>Adresa: </h5>
                </div>
                <div className='ms-5'>
                    <h3><b>{order.user.address}</b></h3>
                </div>
               

          
              
            </div>


            <div className='col-md-7 itemContainerOrderCompleted'>
                <br/>
                {order.shoppingCart.map(item => {
                    return <div key={item.id_product} className="rounded">
                        <div className='row col-md-12 m-0'>
                        <div className='col-md-5'>
                        <img className='img-fluid mb-2' style={{maxWidth:'50%'}}src={`${process.env.REACT_APP_IMAGES_LINK}` + item.image}></img>
                        </div>
                        <div className='col-md-7'>
                        <h4 className='mt-3'><b>{item.name} </b></h4>
                        <h5 className='mt-3'>Cena za jeden Ks: {item.price} Kč</h5>
                        <h5>Cena při počtu {item.quantity}: {(item.price * item.quantity).toFixed(2)} Kč </h5>
                        </div>
                        </div>
                        <hr/>
                       
                    </div>
                })}
                 <div className='d-flex w-100 ms-5'>
                    <h5 style={{color: "grey"}}>Cena: </h5>
                </div>
                <div className='ms-5'>
                    <h2><b>{(parseFloat(price) + parseFloat(order.user.deliveryPrice)).toFixed(2)} Kč</b></h2>
                    
                </div>
            </div>
        </div>
    </div>
  )
}

export default OrderCompleted