import { useRef } from 'react'

import { Alert, Button, Form } from 'react-bootstrap'
import { useDataContext } from "../Context/DataContext"
const Login = () => {

  const { login, err } = useDataContext()
    
  const email = useRef()
  const password = useRef()

  const handleSend = () => {
    login({email: email.current.value, password: password.current.value})
  }
  return (
      <div  style={{paddingTop: '100px', minHeight: "71vh"}}>
        {err && <Alert variant='danger' className='m-3 w-100'>{err}</Alert>}
        <Form className='w-100' onSubmit={(e) => e.preventDefault()}>
          <Form.Group className="w-100 m-3">
            <Form.Label>E-mail</Form.Label>
            <Form.Control type="text" placeholder="E-mail" ref={email} required />
          </Form.Group>
          <Form.Group className="m-3 w-100">
            <Form.Label>Heslo</Form.Label>
            <Form.Control type="password" placeholder="Heslo" ref={password} required />
          </Form.Group>
          <Button onClick={handleSend} className="m-3 w-100" type="submit">Přihlásit se</Button>
        </Form>
      </div>
  )
}

export default Login