import React, { useState, useEffect } from 'react';
import { FaChevronRight } from 'react-icons/fa';
import { useDataContext } from '../Context/DataContext';

const Categories = ({ handleChange }) => {
  const { categories } = useDataContext();
  const [selectedCategoryId, setSelectedCategoryId] = useState(1);

  const handleCategoryClick = (category) => {
    handleChange(category);
    setSelectedCategoryId(category.id_category);
  };

  return (
    <div className='d-flex flex-wrap justify-content-center categories'>
      {categories?.map((category) => {
        return (
          <div
            key={category.id_category}
            className={`shadow-lg category ${
              selectedCategoryId == category.id_category ? 'selected' : ''
            }`}
            onClick={() => handleCategoryClick(category)}
          >
            <div style={{ overflow: 'hidden' }} className='d-flex align-items-center justify-content-between p-3'>
              <div style={{ overflow: 'hidden' }} className='d-flex align-items-center'>
                <img
                  style={{ maxWidth: '60px' }}
                  className='me-3 img-fluid'
                  src={`${process.env.REACT_APP_IMAGES_LINK}/${category.image}`}
                  alt=''
                />
                <h5 className='m-0'>{category.name}</h5>
              </div>
              <FaChevronRight className='icona' />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Categories
