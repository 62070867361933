import React, { useEffect, useState, useRef } from 'react'
import { Alert } from 'react-bootstrap'
import { useDataContext } from "../../../Context/DataContext"
import ItemInQuantity from './Components/ItemInQuantity'
import BackButton from '../../SMComponents/BackButton'
  
import { useNavigate } from 'react-router-dom'

const ChangeQuantity = () => {
  const history = useNavigate()
  const [shownData, setShownData] = useState()
  const { items, getItems } = useDataContext()
  
  useEffect(() => {
      setShownData(items)
    }, [items]);

    useEffect(() => {
      getItems()
    }, [])

  const search = useRef()

  const handleSearch = () => {
    let val = search.current.value
    const filteredData = items.filter((el) => {
        if (val === '') {
            return el;
        }
        else {
            return (el.name).toLowerCase().includes(val.toLowerCase())
        }
    })
    setShownData(filteredData.sort((a, b) => ((a.name).toLowerCase()).localeCompare((b.name).toLowerCase())))
  }

  return (
    <div style={{paddingTop: '100px', minHeight: "71vh"}}>
      <BackButton link="/admin" />
      <div className='d-flex justify-content-center'>
        <input type="search" className="form-control w-75" placeholder="Hledej" onChange={handleSearch} ref={search}/>
      </div>
        <table className="table-responsive mt-3">
      <thead>
        <tr>
          <th>#</th>
          <th>Jméno</th>
          <th>Cena</th>
        </tr>
      </thead>
      <tbody>
      {shownData?.map(item => {
        return <tr key={item.id_product} onClick={() => history(`/editItem/${item.id_product}`) } style={item.isAvalible == 0 ? { backgroundImage: "linear-gradient(rgb(0 0 0/20%) 0 0)" } : {}} >
          <td style={{width: '15px'}}><img src={`${process.env.REACT_APP_IMAGES_LINK}` + item.image} alt="" className='imgItemInQuantity' /></td>
          <td>{item.name}</td>
          <td>{item.price}</td>
        </tr>
      })}
      </tbody>
    </table>
    </div>
  )
}

export default ChangeQuantity